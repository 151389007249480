import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import constant from "../../Components/Services/constant";
import { ApiService } from "../../Components/Services/apiservices";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { Helmet } from "react-helmet";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { ColorRing } from "react-loader-spinner";

const apiServices = new ApiService();

function UpdatedGallery() {
  const [galleryData, setGalleryData] = useState([]);
  const [imAGESARRAY, setIMAGESARRAY] = useState([]);
  const [galleryImageUrl, setGalleryImageUrl] = useState("");
  const [pageData, setPageData] = useState({});
  const [headerImageUrl, setHeaderImageUrl] = useState("");
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxOpenIndex, setLightboxOpenIndex] = useState(0);
  const [spinnerLoading, setspinnerLoading] = useState(false);

  useEffect(() => {
    const dataString2 = {
      page_url: "gallery",
    };

    apiServices.pagePostRequest(dataString2).then((res) => {
      if (res.data.status === "success" && res.data.pageData != null) {
        setPageData(res.data.pageData);
        setHeaderImageUrl(res.data.page_header_url);
      }
    });

    apiServices.allGalleryDataGetRequest().then((res) => {
      if (res.data.status == "success") {
        setGalleryData(res.data.gallery);
        setIMAGESARRAY(res.data.imagesarray);
        setGalleryImageUrl(res.data.gallery_url);
      }
    });
  }, []);

  const openLightbox = (index) => {
    setspinnerLoading(true);
    setLightboxOpenIndex(index);
    setTimeout(() => {
      setspinnerLoading(false);
      setLightboxOpen(true);
    }, 500);
  };

  const closeLightbox = () => {
    setspinnerLoading(false);
    setLightboxOpen(false);
  };
  return (
    <>
      <Helmet>
        <title>{pageData?.page_meta_title != null ? pageData.page_meta_title : "Chokha Punjab"}</title>
        <meta
          name="description"
          itemprop="description"
          content={pageData?.page_meta_desc != null ? pageData?.page_meta_desc :"Chokha Punjab"}
        />
        <meta name="keywords" content={pageData.page_meta_keyword} />
        <link rel="canonical" href={window.location.href} />
        <meta name="robots" content="index,follow" />
        <meta name="author" content="Chokha Punjab" />
        <meta property="og:title" content={pageData.page_meta_title} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Chokha Punjab" />
        <meta property="og:url" content={window.location.href} />
        {pageData?.page_header_image ? (
                  <meta property="og:image" content={headerImageUrl + pageData.page_header_image} />
                ) : (
                  <meta
                    property="og:image"
                    content={constant.FRONT_URL + '/assets/img/logo.png'}
                  />
                )}
        {pageData?.page_meta_desc != null ? (
            <meta property="og:description" content={pageData?.page_meta_desc } />
            ) : (
            <meta property="og:description" content="Chokha Punjab" />
            )}

        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=5,user-scalable=1"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Chokha Punjab" />
        <meta name="twitter:title" content={pageData.page_meta_title} />
        {pageData?.page_meta_desc != null ? (
                    <meta property="twitter:description" content={pageData?.page_meta_desc} />
                ) : (
                    <meta property="twitter:description" content="Chokha Punjab" />
                )}
        {pageData?.page_header_image ? (
                  <meta property="twitter:image" content={ headerImageUrl + pageData.page_header_image} />
                ) : (
                  <meta
                  property="twitter:image"
                    content={constant.FRONT_URL + '/assets/img/logo.png'}
                  />
                )}
        <meta name="twitter:url" content={window.location.href} />
      </Helmet>

      <Header />
      <div
        className={spinnerLoading == true ? "parentDisable" : ""}
        width="100%"
      >
        <div className="overlay-box">
          <ColorRing
            visible={spinnerLoading}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
          />
        </div>
      </div>

      <div className="subheader">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {pageData.page_header != null ? (
                <h1>{pageData.page_header}</h1>
              ) : (
                ""
              )}
              {pageData.page_sub_header != null ? (
                <h5>{pageData.page_sub_header}</h5>
              ) : (
                ""
              )}
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {pageData.page_header}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <section className="section-padding">
        <div className="container">
          <div className="row align-items-center">
            <div className="section-title">
              <div className="my-5">
                <h2 style={{ textAlign: "center" }}>
                Masti. Khana. Punjab Ka Alag Andaz.
                </h2>
              </div>
            </div>
            {galleryData && galleryData.length > 0
              ? galleryData.map((value, index) => (
                  <div className="col-lg-3" key={index}>
                    <div
                      className="gallerybox overlay-zoom"
                      onClick={(e) => openLightbox(index)}
                    >
                      <figure className="gallerybox-media">
                        <img
                          src={
                            value.gallery_image != null
                              ? galleryImageUrl + value.gallery_image
                              : constant.DEFAULT_IMAGE
                          }
                          alt="Gallery"
                        />
                      </figure>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      <Footer />

      {lightboxOpen && (
        <Lightbox
          mainSrc={imAGESARRAY[lightboxOpenIndex]}
          nextSrc={imAGESARRAY[(lightboxOpenIndex + 1) % imAGESARRAY.length]}
          prevSrc={
            imAGESARRAY[
              (lightboxOpenIndex + imAGESARRAY.length - 1) % imAGESARRAY.length
            ]
          }
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() =>
            setLightboxOpenIndex(
              (lightboxOpenIndex + imAGESARRAY.length - 1) % imAGESARRAY.length
            )
          }
          onMoveNextRequest={() =>
            setLightboxOpenIndex((lightboxOpenIndex + 1) % imAGESARRAY.length)
          }
        />
      )}
    </>
  );
}

export default UpdatedGallery;
