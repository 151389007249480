import React, { useEffect, useRef, useState } from "react"
import Modal from "react-bootstrap/Modal";
import { ApiService } from "../../Components/Services/apiservices";
import numeral from 'numeral';
import { validEmail, validNumber } from '../../Components/Elements/Regex';
import Alert from 'react-bootstrap/Alert';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
let apiServices = new ApiService();

const BookingModal = () => {
  const [show, setShow] = useState(false);
  const [couponApplyStatus, setCouponApplyStatus] = useState(false);
  const [step, setStep] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [daterange, setdaterange] = useState("")
  const [successMessage, setSuccessMessage] = useState("");
  const [adultTicketsData, setAdultTicketsData] = useState({});
  const [free_ticket, setfree_ticket] = useState("");
  const [loader, setloader] = useState(false)
  const [applyloader, setapplyloader] = useState(false)
  const [settingData, setSettingData] = useState("")
  const [childTicketsData, setchildTicketsData] = useState({});
  const [bookingDetails, setBookingDetails] = useState({
    no_of_adults: 1,
    no_of_child: 0,
    booking_date: new Date(),
    user_name: "",
    user_mobile: "",
    user_email: "",
    child_price: "",
    adult_price: "",
    booking_type: 0,
  });
  const [itemTotal, setItemTotal] = useState(0)
  const [couponAmount, setCouponAmount] = useState(0)
  const [couponCode, setCouponCode] = useState('')
  const [resCouponInfo, setresCouponInfo] = useState('')
  const [finalAmount, setFinalAmount] = useState(0)
  const didMountRef = useRef(true)
  const current_Date = new Date()
  const handleClose = () => {
    setShow(false);
    setStep(1);
    setBookingDetails((prevState) => ({
      ...prevState,
      ['no_of_adults']: 1,
      ['no_of_child']: 0,
      ['booking_date']: new Date(),
      ['user_name']: '',
      ['user_mobile']: '',
      ['user_email']: '',
      ['booking_type']: 0,
      ['adult_price']: "",
      ['child_price']: ''

    }));
    setCouponApplyStatus(false)
    setCouponCode('')
    setCouponAmount(0)
  }
  
  const handleCoupon = (e) => {
    setErrorMessage("")
    setCouponCode(e.target.value)
  }
  useEffect(() => {
    if (didMountRef.current) {
      getDateData()
      getsettingData()
    }
    didMountRef.current = false

  })
  const getsettingData = (() => {
    apiServices.settingsdataGetrequest().then(res => {
      if (res.data.status == "success") {
        setSettingData(res.data.sitesettings)
      }
    }
    )

  })
  const showmodal = () => {
    setShow(true);

  };

  const getDateData = (() => {
    const dataString = {
      date: moment(new Date().toString()).format('YYYY-MM-DD')
    };
    apiServices.ticketslistPostRequest(dataString).then((res) => {
      if (res.data.status === "success") {
        setAdultTicketsData(res.data.ticketsdata[1]);
        setchildTicketsData(res.data.ticketsdata[0]);
        setdaterange(res?.data?.range);
      }
    });
  });

  const validateBookingDetails = () => {
    setErrorMessage("");
    setSuccessMessage("");
    setCouponApplyStatus(false)
    setCouponCode('')
    setCouponAmount(0)
    if (bookingDetails.no_of_adults === "" && bookingDetails.no_of_child === "") {
      setErrorMessage("Please enter No of Adult or No of Child");
      return false;
    }

    if (bookingDetails.no_of_adults < 0) {
      setErrorMessage("Please enter valid No of Adult");
      return false;
    }

    if (bookingDetails.no_of_child < 0) {
      setErrorMessage("Please enter valid No of Child");
      return false;
    }

    if (bookingDetails.no_of_child == 0 && bookingDetails.no_of_adults == 0) {
      setErrorMessage("Please enter No of Adult or No of Child");
      return false;
    }
    if (bookingDetails.booking_date === "") {
      setErrorMessage("Please choose Date");
      return false;
    }
    setloader(true)
    const dataString = {
      date: moment(bookingDetails?.booking_date.toString()).format(`YYYY-MM-DD`)
    };
    apiServices.ticketslistPostRequest(dataString).then((res) => {
      if (res.data.status == "success") {
        removeCoupon()
        setAdultTicketsData(res.data.ticketsdata[1]);
        setchildTicketsData(res.data.ticketsdata[0]);
        setdaterange(res?.data?.range)
        setBookingDetails((prevState) => ({
          ...prevState,
          child_price: res.data.ticketsdata[0]?.ticket_selling_price,
        }));
        setBookingDetails((prevState) => ({
          ...prevState,
          adult_price: res.data.ticketsdata[1]?.ticket_selling_price,
        }));
        let childamount = Number(bookingDetails.no_of_child) * Number(res.data.ticketsdata[0].ticket_selling_price)
        let adultamount = Number(bookingDetails.no_of_adults) * Number(res.data.ticketsdata[1].ticket_selling_price)
        setItemTotal(Number(childamount + adultamount));
        setFinalAmount(Number(childamount + adultamount));
        setresCouponInfo(res.data.resCouponInfo)
        setloader(false)
        setStep(2);
      }
      else {
        setloader(false)
      }
    })
  };
  const beckStep = () => {
    setStep(1);
    setSuccessMessage("")
    setErrorMessage("")
    setCouponApplyStatus(false)
    setCouponCode('')
    setCouponAmount(0)

  }
  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + daterange);
  const orderTransaction = () => {
    setErrorMessage("");
    if (bookingDetails.user_name === "") {
      setErrorMessage("Please enter Full Name");
      return false;
    } else if (bookingDetails.user_mobile === "") {
      setErrorMessage("Please enter Mobile Number");
      return false;
    } else if (!validNumber.test(bookingDetails.user_mobile)) {
      setErrorMessage("Please enter valid Mobile Number");
      return false;
    } else if (bookingDetails.user_email === '') {
      setErrorMessage("Please enter Email Id");
      return false;
    } else if (!validEmail.test(bookingDetails.user_email)) {
      setErrorMessage("Please enter valid Email Id");
      return false;
    }
     else if (bookingDetails.no_of_child < 0) {
      setErrorMessage("Please enter valid No of Child");
      return false;
    }else if (bookingDetails.no_of_adults < 0) {
      setErrorMessage("Please enter valid No of Adult");
      return false;
    }
    else if (bookingDetails.no_of_child == 0 && bookingDetails.no_of_adults == 0) {
      setErrorMessage("Please enter No of Adult or No of Child");
      return false;
    }
    
    setloader(true)
    const dataString = {
      "promocode": couponCode,
      "totalAmount": itemTotal,
      "couponAmount": couponAmount,
      "couponCode": couponCode,
      "finalAmount": finalAmount,
      "free_ticket": free_ticket,
      bookingDetails,

    }
    apiServices.createdPaytmOrderId(dataString).then((res) => {
      if (res.data.status == "success") {
        console.log(res.data.row_temp_trans.temp_trans_token);
        if (res.data.row_temp_trans) {
          onScriptLoad(res.data.row_temp_trans.temp_trans_token, res.data.row_temp_trans.temp_trans_order_id, res.data.row_temp_trans.temp_trans_finalamt)
          setTimeout(() => {
            setloader(false)
          }, 1600)
        }
      } else {
        setloader(false)
        setErrorMessage(res.data.message)
      }
    });

  };


  const onScriptLoad = (txnToken, orderId, amount) => {
    var config = {
      "root": "",
      "flow": "DEFAULT",
      "merchant": {
        "name": "Chokha Punjab",
        "logo": " https://chokhapunjab.com/assets/img/logo.png"
      },
      "style": {
        "headerBackgroundColor": "#8dd8ff",
        "headerColor": "#3f3f40"
      },
      "data": {
        "orderId": orderId,
        "token": txnToken,
        "tokenType": "TXN_TOKEN",
        "amount": amount
      },
      "handler": {
        "notifyMerchant": function (eventName, data) {
          if (eventName == 'SESSION_EXPIRED') {
            alert("Your session has expired!!");
            window.location.reload();
          }
        }
      }
    };

    if (window.Paytm && window.Paytm.CheckoutJS) {
      window.Paytm.CheckoutJS.init(config)
        .then(function onSuccess() {
          console.log('Before JS Checkout invoke');
          window.Paytm.CheckoutJS.invoke();
        })
        .catch(function onError(error) {
          console.log('Error => ', error);
        });
    }
  };

  const onTodoChange = (e) => {
    setErrorMessage("")
    const { name, value } = e.target;
    setBookingDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const applyCoupon = () => {
    if (couponCode === '') {
      setErrorMessage('Please enter coupon code')
      return false
    }
    setapplyloader(true)
    const dataString = {
      "promocode": couponCode,
      "totalAmount": itemTotal,
      "totalTickets": Number(bookingDetails.no_of_adults) + Number(bookingDetails.no_of_child),
      "no_of_adults": Number(bookingDetails.no_of_adults),
      "no_of_child": Number(bookingDetails.no_of_child),
      "booking_Date": moment(bookingDetails.booking_date).format('YYYY-MM-DD'),
      "bookingDetails":bookingDetails
    }
    apiServices.applycouponPostRequest(dataString).then((res) => {
      if (res.data.data.status == "success") {
        setfree_ticket(res.data.data.free_ticket)
        setCouponApplyStatus(true)
        setCouponAmount(res.data.data.discountAmount)
        setFinalAmount(Number(finalAmount) - Number(res.data.data.discountAmount));
        setapplyloader(false)
        setSuccessMessage(res.data.data.notification)
        setTimeout(() => {
          setSuccessMessage("")
          setErrorMessage("")
        }, 20000);
      } else {
        setapplyloader(false)
        setCouponCode("")
        setCouponAmount(Number(0.00))
        setfree_ticket(0)
        setErrorMessage(res.data.data.notification)
        setTimeout(() => {
          setSuccessMessage("")
          setErrorMessage("")
        }, 6000);
      }
    });
  }

  const removeCoupon = () => {
    setCouponCode("")
    setErrorMessage("")
    setCouponAmount(Number(0.00))
    setFinalAmount(Number(finalAmount) + Number(couponAmount));
    setCouponApplyStatus(false)
    setfree_ticket(0)
  }

  const formatDate = (date) => {
    setBookingDetails((prevState) => ({
      ...prevState,
      booking_date: date,
    }))
  };

  return (
    <div>
      <a href="javascript:;" className="btn btn-warning btn01 btn01-large" onClick={showmodal}>Book Now </a>
      {show && (
        <Modal show={show} onHide={handleClose} className="btmodal">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          ></button>
          <div className="btmodal-content bookform">
            {step === 1 && (
              <div className="step-first">
                <div className="section-title">
                  <span className="section-title__tagline">Make a booking</span>
                  <h4 className="section-title__title-small">
                    Booking at Chokha Punjab
                  </h4>
                </div>
                {successMessage !== "" ? (
                  <Alert variant="success">{successMessage}</Alert>
                ) : null}
                {errorMessage !== "" ? (
                  <Alert variant="danger">{errorMessage}</Alert>
                ) : null}
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group mb-3">
                      <label>No of Adult</label>
                      <input
                        type="number"
                        placeholder="No of Adult"
                        name="no_of_adults"
                        min="0"
                        value={bookingDetails.no_of_adults}
                        onChange={(e) => onTodoChange(e)}
                      ></input>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group mb-3">
                      <label>No of Child</label>
                      <input
                        type="number"
                        placeholder="No of Child"
                        name="no_of_child"
                        min="0"
                        value={bookingDetails.no_of_child}
                        onChange={(e) => onTodoChange(e)}
                      ></input>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group mb-3">
                      <label>Date</label>
                      <DatePicker
                        selected={bookingDetails?.booking_date}
                        dateFormat="dd/MM/yyyy"
                        name="booking_date"
                        onChange={(date) => { formatDate(date) }}
                        minDate={new Date()}
                        maxDate={maxDate}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group mb-3">
                      <label>&nbsp;</label>
                      <button
                        className="btn btn-warning btn01 btn01-medium book-but"
                        onClick={validateBookingDetails}
                        disabled={settingData.booking_open_close !== 1 || loader}

                      >
                        {loader ? (
                          <img src="/assets/img/loder01.gif" width="60px" height="11px" />
                        ) : (
                          "Continue"
                        )}

                      </button>
                    </div>
                  </div>
                  {settingData && settingData.booking_open_close === 0 && (
                    <p>
                      <b>Please Note:</b>
                      <span style={{ color: "#ff0000", marginLeft: '5px' }}>{settingData.booking_close_note}</span>
                    </p>
                  )}
                </div>
              </div>
            )}
            {step === 2 && (
              <div className="step-second">
                <div ><i className="icon-arrow-left" onClick={() => { beckStep() }}></i>
                </div>
                <div className="btmodal-left">
                  <div className="section-title">
                    <span className="section-title__tagline">
                      Make a booking
                    </span>
                    <h4 className="section-title__title-small">
                      Enter your details
                    </h4>
                  </div>
                  {successMessage !== "" ? (
                    <Alert variant="success">{successMessage}</Alert>
                  ) : null}
                  {errorMessage !== "" ? (
                    <Alert variant="danger">{errorMessage}</Alert>
                  ) : null}
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label>Full Name</label>
                        <input
                          type="text"
                          name="user_name"
                          value={bookingDetails.user_name}
                          onChange={(e) => onTodoChange(e)}
                        ></input>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label>Mobile Number</label>
                        <input
                          type="number"
                          name="user_mobile"
                          value={bookingDetails.user_mobile}
                          onChange={(e) => onTodoChange(e)}
                        ></input>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label>Email Id</label>
                        <input
                          type="email"
                          name="user_email"
                          value={bookingDetails.user_email}
                          onChange={(e) => onTodoChange(e)}
                        ></input>
                      </div>
                    </div>
                    {resCouponInfo && (
                      <>
                        <h5 className="wow fadeInUp" data-wow-delay="0.4s" style={{
                          color: "#094470",
                          backgroundColor: "#f8f9fa",
                          padding: "10px",
                          borderRadius: "5px",
                          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                          marginBottom: "20px",
                          textAlign: "center",
                          fontWeight: "bold",
                        }}>
                          {resCouponInfo.promo_name}
                        </h5>
                        <p style={{ padding: "10px" }} dangerouslySetInnerHTML={{ __html: resCouponInfo.promo_description }}></p>
                      </>
                    )}
                  </div>
                </div>
                <div className="btmodal-right">
                  <div className="form-group">
                    <div className="apply-coupons-box">
                      <input
                        type="text"
                        placeholder="Gift card or discount code"
                        value={couponCode}
                        onChange={e => handleCoupon(e)}
                      />
                      {couponApplyStatus === false ?
                        <button className="btn btn-warning btn01-medium book-but mt-2" onClick={applyCoupon} disabled={applyloader}>
                          {applyloader ? (
                            <img src="/assets/img/loder01.gif" width="60px" height="11px" />
                          ) : (
                            "Apply"
                          )}

                        </button>
                        :
                        <button className="btn btn-warning btn01-medium book-but mt-2" onClick={removeCoupon}>
                          Remove
                        </button>
                      }
                    </div>
                  </div>
                  <div className="price-details-box mt-4">
                    <h5>Price Details</h5>
                    <div className="pcb-list mb-4">
                      <ul>
                        <li>
                          Adult X {bookingDetails.no_of_adults}<span className="ml-auto">₹{numeral(Number(bookingDetails.no_of_adults) * Number(adultTicketsData.ticket_selling_price)).format('0,0.00')}</span>
                        </li>
                        <li>
                          Child X {bookingDetails.no_of_child}<span className="ml-auto">₹{numeral(Number(bookingDetails.no_of_child) * Number(childTicketsData.ticket_selling_price)).format('0,0.00')}</span>
                        </li>
                        <li>
                          Coupon Discount
                          <span className="ml-auto tx-green">₹{numeral(couponAmount).format('0,0.00')}</span>
                        </li>
                      </ul>
                    </div>
                    <hr />
                    <div className="pcb-list-second">
                      <ul>
                        <li>
                          Total Amount<span className="ml-auto">₹{numeral(finalAmount).format('0,0.00')}</span>
                        </li>
                      </ul>
                    </div>
                    <hr />
                  </div>
                  <div className="form-group mb-3">
                    <button
                      className="btn btn-warning btn01 btn01-medium book-but"
                      onClick={() => {
                        orderTransaction();
                      }}
                      disabled={loader}

                    >
                      {loader ? (
                        <img src="/assets/img/loder01.gif" width="60px" height="11px" />
                      ) : (
                        "Pay Now"
                      )}

                    </button>

                  </div>
                </div>
              </div>
            )}
            {step === 3 && (
              <div className="step-first text-center">
                <div className="section-title">
                  <img src="/assets/img/check-circle.gif" style={{ width: '100px' }} className="mb-20" />
                  <h2><strong>Thank You</strong></h2>
                  <h5>Your Enquiry Successfully Submitted</h5>
                  {/* <p>Your booking ID <strong>#{orderId}</strong></p> */}
                  <p>Please check your email for ticket booking details.</p>
                </div>
              </div>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default BookingModal;
