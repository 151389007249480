import React, { useEffect, useRef, useState } from "react"
import moment from 'moment';
import constant from '../../Components/Services/constant';
import { ApiService } from "../../Components/Services/apiservices";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css/pagination';
import 'swiper/css';
let apiServices = new ApiService();

function HomeBlogs(){

    const [blogsData, setBlogsData] = useState([]);
    const [blogUrl, setBlogUrl] = useState('');
    const didMountRef = useRef(true);

    useEffect(()=>{
        if(didMountRef.current){
          apiServices.getfeaturedblogs().then(res=>{ 
            if(res.data.status == "success"){
              setBlogsData(res.data.blogsData);
              setBlogUrl(res.data.blog_url);
        
        }})
        didMountRef.current = false;
        }
        })
// Rest of your imports and code...

return (     
    <>
        <section className="section">
            <div className="container">
                <div className="section-title text-center mb-40 wow fadeInUp" data-wow-delay="0.4s">
                    <h3 className="style-font"> Charcha Chronicles</h3>
                    <h2>News & Blog</h2>
                </div>
                <div className="row align-items-center">
                <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.4s">
   
                    <Swiper
                        modules={[Pagination]}
                        slidesPerView={3}
                        spaceBetween={15}
                        loop
                        breakpoints={{
                            340: {
                                width: 340,
                                slidesPerView:0.9,
                                },
                            640: {
                                width: 640,
                                slidesPerView: 0.9,
                            },
                            768: {
                                width: 768,
                                slidesPerView: 3,
                            },
                            1000: {
                                width: 1000,
                                slidesPerView: 6,
                                },
                            1200: {
                                width: 1200,
                                slidesPerView: 3,
                                },
                            }} > 
                        {blogsData.map((value, index) => (
                            <SwiperSlide key={index}>
                              
                                    <div className="edu-blog blog-style-1">
                                        <div className="inner">
                                            <div className="thumbnail">
                                                <a href={`/blogs/${value.blog_slug}`}>
                                                    <img src={value.blog_image != null ? blogUrl + value.blog_image : constant.DEFAULT_IMAGE} alt="" />
                                                </a>
                                            </div>
                                            <div className="content">
                                                <h5><a href={`/blogs/${value.blog_slug}`}>{value.blog_name}</a></h5>
                                                <ul className="blog-meta">
                                                    <li><i className="icon-calendar-alt"></i>{moment(value.created_at).format('MMM DD, YYYY')}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                     
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    </div>
                </div>
            </div>
        </section>
    </>
)

}
export default HomeBlogs