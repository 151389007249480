import React, { useEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { ApiService } from '../Services/apiservices';
import { useLocation } from "react-router-dom";
import BookingModal from '../../Containers/BookingModal';
import Offers from '../Modal/offers';
let apiServices = new ApiService();
function Header() {
    const [show , setShow] = useState(false)
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);
const didMountRef = useRef(true)
    const location = useLocation();
    const[settingData, setSettingData] = useState("")
    useEffect(()=>{
        if(didMountRef.current){     
            apiServices.settingsdataGetrequest().then((res) => {
                if (res.data.status == "success") {
                    setSettingData(res.data.sitesettings)
            
                }
              });  
       
        }
        didMountRef.current = false;
    })
    return (
        <>
       {settingData.marquee_tag ? (
        <div className="bg-theme">
            <div className ="container">
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-10">
                  <marquee style={{color: "#fff"}}>{settingData.marquee_tag}</marquee>
                </div>
              </div>
              </div>
          </div>
       ) : null}
            <header  id="dtr-header-global" className="fixed-top">
                <nav className="navbar navbar-expand-lg navbar-light">
                    <div className="container">
                        <a className="navbar-brand" href="/"><img src="/assets/img/logo.png" className="logo" /></a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent" >
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                <li className="nav-item"><a href="/" className={location.pathname ==="/" ? "nav-link active" : "nav-link"}>Home</a></li>
                                <li className="nav-item"><a href="/about" className={location.pathname.includes("/about") ? "nav-link active" : "nav-link"}>About</a></li>
                                <li className="nav-item"><a href="/menu" className={location.pathname.includes("/menu") ? "nav-link active" : "nav-link"}>Menu</a></li>
                                <li className="nav-item"><a href="/gallery" className={location.pathname.includes("/gallery") ? "nav-link active" : "nav-link"}>Gallery</a></li>
                                <li className="nav-item"><a href="/activities" className={location.pathname.includes("/activities") ? "nav-link active" : "nav-link"}>Activities</a></li>
                                <li className="nav-item"><a href="/blogs" className={location.pathname.includes("/blogs") ? "nav-link active" : "nav-link"}>Blogs</a></li>
                                {/* <li className="nav-item"><a href="/offers" className={location.pathname.includes("/offers") ? "nav-link active" : "nav-link"}>Offers</a></li> */}
                                <li className="nav-item"><a href="/offers" style={{color: "#fff",backgroundColor: "#b32c28",borderColor: "#b32c28",borderRadius:"5px"}} className={location.pathname.includes("/offers") ? "nav-link active" : "nav-link"}>Offers</a></li>

                                <li className="nav-item"><a href="/events" className={location.pathname.includes("/events") ? "nav-link active" : "nav-link"}>Events</a></li>
                                <li className="nav-item"><a href="/memes" className={location.pathname.includes("/memes") ? "nav-link active" : "nav-link"}>Memes</a></li>

                                <li className="nav-item"><a href="/contact" className={location.pathname.includes("/contact") ? "nav-link active" : "nav-link"}>Contact Us</a></li>
                            </ul>
                            <div className="d-flex">
                             <a href="https://chokhidhani.in/" target='_blank' className="btn btn-outline-dark btn-nav">Reservations</a>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>

            <div className="sidebutton">
                <a href="/book-tickets">Book Tickets</a>
                <a href="https://chokhidhani.in/" target="_blank">Reservations</a>
                </div>

            <Offers
            
            show={show}
            setShow={setShow}
            handleClose={handleClose}
            handleShow={handleShow}
            
            />
                </>
    )
}
export default Header