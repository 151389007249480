import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
function HomeVideo() {
    return (
        <>

                <section className="section-padding">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className='col-lg-12'>
                                <div className="video-container">
                                    <iframe width="100%" height="600" src="https://www.youtube.com/embed/m2odhCkQJ3k?si=tehhL0_73Hl_IT3L"
                                        title="YouTube video player" frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerpolicy="strict-origin-when-cross-origin"
                                        allowfullscreen>

                                    </iframe>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>


            {/* <MobileView>
            <section className="section-padding">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className='col-lg-12'>
                                <div className="video-container">
                                <iframe width="100%" height="315" src="https://www.youtube.com/embed/m2odhCkQJ3k?si=YylYuVJnms1D0DCf" 
                                title="YouTube video player" frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>


            </MobileView> */}
        </>


    );
}

export default HomeVideo;
