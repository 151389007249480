import React from 'react';
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"

function NotFound(){

  return (
    <>
    <Header/>
    
   <div className="container">
   <div className="row">
    <div className="col-lg-12">
       <div className="text-center mt-100">
      <img src="/assets/img/hero.png" style={{width:"300px"}}/>
      <h1>Page Not Found</h1>
      <p>The Page that you are looking for is either getting updated or there's server issue.
Kindly revisit again at: <a href="/">https://chokhapunjab.com/</a></p>

<p>If the issue pertains, Mail us at: <a href={"mailto:" + "front_office@pinkpearl.co.in"}>front_office@pinkpearl.co.in</a></p>
    </div>
   </div>
   </div>
   </div>

        <Footer/>
        </>
  );
};

export default NotFound;