import React, { useEffect, useRef, useState } from "react"
import { ApiService } from "../../Components/Services/apiservices"
import { useParams, useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { Helmet } from "react-helmet";
import constant from "../../Components/Services/constant";

let apiServices = new ApiService();
function Pages(){
    const [pageData, setPageData] = useState({})
    const [pageContent, setPageContent] = useState('');
    const [headerImageUrl, setHeaderImageUrl] = useState('');
    const { slug } = useParams();
    const navigate = useNavigate();
    const didMountRef = useRef(true)

useEffect(() => {
    
 if (didMountRef.current) {
    console.log(slug)
const dataString={
    'page_url' : slug 
}
apiServices.pagePostRequest(dataString).then(res=>{
    if (res.data.status === "success" && res.data.pageData !== null) {    
          setPageData(res.data.pageData)
          setPageContent(res.data.pageData.page_content)
          setHeaderImageUrl(res.data.page_header_url)

    }
    else {
        // Redirect to 404 page
        navigate("/404");
      }
})}
        didMountRef.current = false;
    })
return(

<>
<Helmet>
        <title>{pageData?.page_meta_title != null ? pageData.page_meta_title : "Chokha Punjab"}</title>
        <meta
          name="description"
          itemprop="description"
          content={pageData?.page_meta_desc != null ? pageData?.page_meta_desc :"Chokha Punjab"}
        />
        <meta name="keywords" content={pageData.page_meta_keyword} />
        <link rel="canonical" href={window.location.href} />
        <meta name="robots" content="index,follow" />
        <meta name="author" content="Chokha Punjab" />
        <meta property="og:title" content={pageData.page_meta_title} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Chokha Punjab" />
        <meta property="og:url" content={window.location.href} />
        {pageData?.page_header_image ? (
                  <meta property="og:image" content={headerImageUrl + pageData.page_header_image} />
                ) : (
                  <meta
                    property="og:image"
                    content={constant.FRONT_URL + '/assets/img/logo.png'}
                  />
                )}
        {pageData?.page_meta_desc != null ? (
            <meta property="og:description" content={pageData?.page_meta_desc } />
            ) : (
            <meta property="og:description" content="Chokha Punjab" />
            )}

        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=5,user-scalable=1"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Chokha Punjab" />
        <meta name="twitter:title" content={pageData.page_meta_title} />
        {pageData?.page_meta_desc != null ? (
                    <meta property="twitter:description" content={pageData?.page_meta_desc} />
                ) : (
                    <meta property="twitter:description" content="Chokha Punjab" />
                )}
        {pageData?.page_header_image ? (
                  <meta property="twitter:image" content={ headerImageUrl + pageData.page_header_image} />
                ) : (
                  <meta
                  property="twitter:image"
                    content={constant.FRONT_URL + '/assets/img/logo.png'}
                  />
                )}
        <meta name="twitter:url" content={window.location.href} />
      </Helmet>
<Header/>

<div className="subheader"> 
<div className="container">
<div className="row">
<div className="col-lg-12">
{pageData.page_header != null  ? <h1>{pageData.page_header}</h1>:""}
{pageData.page_sub_header != null ?<h5>{pageData.page_sub_header}</h5>:""}

<nav aria-label="breadcrumb">
  <ol className="breadcrumb">
    <li className="breadcrumb-item"><a href="/">Home</a></li>
    <li className="breadcrumb-item active" aria-current="page">{pageData.page_header}</li>
  </ol>
</nav>

</div>
</div>
</div>
</div>
{pageContent != null ? <div dangerouslySetInnerHTML={{ __html: pageContent }}></div> : ""}

<Footer />
</>

)}

export default Pages;