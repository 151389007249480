import React, { useEffect, useRef, useState } from "react"
import constant from '../../Components/Services/constant';
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import { ApiService } from "../../Components/Services/apiservices"
import {Helmet} from  "react-helmet"

let apiServices = new ApiService();

function Menu() {
  const [activitiesData, setActivitiesData] = useState([])
  const [baseUrl, setBaseUrl] = useState("")
  const [menuData, setMenuData] = useState([]);
  const [menuImageUrl, setMenuImageUrl] = useState("");
  const [pageData, setPageData] = useState({});
  const [headerImageUrl, setHeaderImageUrl] = useState('');

  const didMountRef = useRef(true)

  useEffect(() => {

    if (didMountRef.current) {
      apiServices.activitiesGetRequest().then(res => {
        if (res.data.status == "success") {
          setActivitiesData(res.data.activitydata)
          setBaseUrl(res.data.activity_url)

        }
      })
      const dataString = {
        "page_url": "menu"
      };

      apiServices.pagePostRequest(dataString).then(res => {
        if (res.data.status === "success" && res.data.pageData != null) {
          setPageData(res.data.pageData);
          setHeaderImageUrl(res.data.page_header_url);
        }
      });

      apiServices.menuDataGetRequest().then(res => {
        if (res.data.status == "success") {
          setMenuData(res.data.menu);
          setMenuImageUrl(res.data.menu_url);

        }

      })


    }
    didMountRef.current = false;
  })
  return (
    <>
      <Helmet>
        <title>{pageData?.page_meta_title != null ? pageData.page_meta_title : "Chokha Punjab"}</title>
        <meta
          name="description"
          itemprop="description"
          content={pageData?.page_meta_desc != null ? pageData?.page_meta_desc : "Chokha Punjab"}
        />
        <meta name="keywords" content={pageData.page_meta_keyword} />
        <link rel="canonical" href={window.location.href} />
        <meta name="robots" content="index,follow" />
        <meta name="author" content="Chokha Punjab" />
        <meta property="og:title" content={pageData.page_meta_title} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Chokha Punjab" />
        <meta property="og:url" content={window.location.href} />
        {menuData[0]?.menu_image ? (
          <meta property="og:image" content={menuImageUrl + menuData[0]?.menu_image} />
        ) : (
          <meta
            property="og:image"
            content={constant.FRONT_URL + '/assets/img/logo.png'}
          />
        )}
        {pageData?.page_meta_desc != null ? (
          <meta property="og:description" content={pageData?.page_meta_desc} />
        ) : (
          <meta property="og:description" content="Chokha Punjab" />
        )}

        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=5,user-scalable=1"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Chokha Punjab" />
        <meta name="twitter:title" content={pageData.page_meta_title} />
        {pageData?.page_meta_desc != null ? (
          <meta property="twitter:description" content={pageData?.page_meta_desc} />
        ) : (
          <meta property="twitter:description" content="Chokha Punjab" />
        )}
        {menuData[0]?.menu_image ? (
          <meta property="twitter:image" content={menuImageUrl + menuData[0]?.menu_image} />
        ) : (
          <meta
            property="twitter:image"
            content={constant.FRONT_URL + '/assets/img/logo.png'}
          />
        )}
        <meta name="twitter:url" content={window.location.href} />
      </Helmet>
      <Header />
      <div className="subheader">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {pageData.page_header != null ? <h1>{pageData.page_header}</h1> : ""}

              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/">Home</a></li>
                  <li className="breadcrumb-item active" aria-current="page">{pageData.page_header}</li>
                </ol>
              </nav>

            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="container">
          <div className="text-center">
            <h3 style={{ marginTop: "30px", marginBottom: "50px" }}>Punjabi Khana, Full-on Drama!</h3>

            <h3 style={{ marginBottom: "50px" }}>Chokha Punjab Menu</h3>
          </div>

          <div className="row mb-30">

            {menuData && menuData.length > 0 ? menuData.map((value, index) => (
              <div className="col-lg-6">
                <div className="d-flex">
                  <div className="menu-item-img"><img alt="menu_image" src={value.menu_image != null ? menuImageUrl + value.menu_image : constant.DEFAULT_IMAGE} />
                  </div>

                  <div className="food-menu-content mt-3 ms-3">
                    <h3 className="food-menu-title">{value.menu_name}</h3>

                    <p dangerouslySetInnerHTML={{ __html: value.menu_desc }}></p>
                  </div>
                </div>
              </div>

            )) : null}

          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}
export default Menu