import React, { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import constant from '../../Components/Services/constant';
import { ApiService } from "../../Components/Services/apiservices";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import {Helmet} from  "react-helmet"

let apiServices = new ApiService();
function BlogDetailPage(){

    const [blogDetailData , setBlogDetailData] = useState({})
    const [blogDetailImageUrl , setBlogDetailImageUrl] = useState("")
    const [categoryData , setCategoryData] = useState([])
    const [pageData , setPageData] = useState({})
    const [headerImage , setHeaderImage] = useState("")

    const {slug} = useParams()
    const didMountRef = useRef(true)
    useEffect(() => {
if(didMountRef.current){
const dataString = {

    "blog_slug" : slug

}

apiServices.blogdetailsPostRequest(dataString).then(res=>{
    if(res.data.status == "success"){
        setBlogDetailData(res.data.blogDetails)
        setBlogDetailImageUrl(res.data.blog_url)
        setCategoryData(res.data.categoryData)
      }
 })

 const dataString2 = {
    "page_url" : "postpage"
     }
     apiServices.pagePostRequest(dataString2).then(res => {
      if(res.data.status === "success"){
          if(res.data.pageData != null){
        setPageData(res.data.pageData)
        setHeaderImage(res.data.page_header_url) 
          }
     }
  })

}
didMountRef.current = false;
    },[])

return(

<>
<Helmet>
        <title>{blogDetailData.blog_meta_title}</title>
        <meta name="description" itemprop="description" content={blogDetailData.blog_meta_desc} />
        <meta name="keywords" content={blogDetailData.blog_meta_keyword} />
        <link rel="canonical" href={window.location.href} />
        <meta name="robots" content="index,follow" />
        <meta name="author" content="Chokha Punjab" />
        <meta property="og:title" content={blogDetailData.blog_meta_title} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Chokha Punjab" />
        {pageData.page_header_image != null ? <meta property="og:image" content= {headerImage + pageData.page_header_image}/>
                :<meta property="og:image" content= {'/assets/img/logo.png'}/>}
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content={blogDetailData.blog_meta_desc} />
        <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=5,user-scalable=1" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Chokha Punjab" />
        <meta name="twitter:title" content={blogDetailData.blog_meta_desc} />
        <meta name="twitter:description" content={blogDetailData.blog_meta_desc} />
        {pageData.page_header_image != null ? <meta property="twitter:image" content= {headerImage + pageData.page_header_image}/>
                :<meta property="twitter:image" content= {'/assets/img/logo.png'}/>}
        <meta name="twitter:url" content={window.location.href} />
      </Helmet>

<Header />
<div className="subheader">
<div className="container">
<div className="row">
<div className="col-lg-12">

<h1>{blogDetailData.blog_name}</h1>

<nav aria-label="breadcrumb">
  <ol className="breadcrumb">
    <li className="breadcrumb-item"><a href="/">Home</a></li>
    <li className="breadcrumb-item "><a href="/">Blogs</a></li>
    <li className="breadcrumb-item active" aria-current="page">{blogDetailData.blog_name}</li>
  </ol>
</nav>
</div>
</div>
</div>
</div>

<section className="section-padding">
<div className="container">
<div className="hero-content row align-items-center">
<div className="col-lg-12">
<img className="w-100 rounded12" src={blogDetailData.blog_image!=null ?blogDetailImageUrl + blogDetailData.blog_image : constant.DEFAULT_IMAGE} style={{marginBottom :"25px"}} alt=""/>
<h2>{blogDetailData.blog_name}</h2>
<p dangerouslySetInnerHTML={{__html : blogDetailData.blog_desc}}></p>
</div>
</div>
</div>
</section>

<Footer />
</>


)
}

export default BlogDetailPage