import React, { useEffect, useRef, useState } from "react"
import { ApiService } from "../../Components/Services/apiservices";
import constant from '../../Components/Services/constant';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css/pagination';
import 'swiper/css';

let apiServices = new ApiService();

function HomeTestimonials() {
  const [testimonialData, setTestimonialData] = useState([])
  const [testimonialImageUrl, setTestimonialImageUrl] = useState("")
  const didMountRef = useRef(true)
  useEffect(() => {
    if (didMountRef.current) {

      apiServices.testimonialsGetRequest().then(res => {
        if(res.data.status == "success"){
        setTestimonialData(res.data.testimonials)
        setTestimonialImageUrl(res.data.testimonial_img_url)
        }
      })
}
    didMountRef.current = false;
  })

  return (
    
      <section className="section-padding pt-0">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 pr-100 pr-sm-0">
              <div className="section-title mtext-center">
              <h3 className="style-font wow fadeInUp" data-wow-delay="0.4s">Testimonials</h3>
                <h2 className="wow fadeInUp" data-wow-delay="0.4s"> Log Ki Kende</h2>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInRight" data-wow-delay="0.4s">
              <Swiper
                modules={[Pagination]}
                slidesPerView= {1}       
                spaceBetween={12}
                loop
                 >
                {testimonialData.map((value,index) => (
                  <SwiperSlide key={value.testimonial_name}>
                      <div className="testimonial-box" key={index}>
                        <div className="testimonial-author">
                          <img src={value.testimonial_image != null ? testimonialImageUrl + value.testimonial_image : constant.DEFAULT_IMAGE} alt="Author" />
                        </div>
                        <div className="testimonial-des">
                          {value.testimonial_desc != null ? <div dangerouslySetInnerHTML={{ __html: value.testimonial_desc }}></div> : ""}
                        </div>
                        <div className="testimonial-details">
                          <div className="designation">
                            <h4>{value.testimonial_name}</h4>
                            <span>{value.testimonial_city}</span>
                          </div>
                        </div>
                      </div>
                  </SwiperSlide>))}
              </Swiper>

            </div>
          </div>
        </div>
      </section>

  )
}
export default HomeTestimonials