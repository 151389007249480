import React, { useEffect, useRef, useState } from "react"
import { ApiService } from "../Services/apiservices"
import CorporateBookingModal from "../../Containers/CorporateBookingModal";
import moment from 'moment'

let apiServices = new ApiService();
function Footer() {
    const [settingData, setSettingData] = useState("")
    const [imageUrl, setImageUrl] = useState("")
    const [defaultImage, setDefaultImage] = useState('')
    const [bookingmodal, setbookingmodal] = useState(false)
    const [footer1, setFooter1] = useState('')
    const [footer2, setFooter2] = useState('')
    const [footer3, setFooter3] = useState('')
    const [footer4, setFooter4] = useState('')
    const didMountRef = useRef(true)
    useEffect(() => {
        if (didMountRef.current) {
            apiServices.settingsdataGetrequest().then(res => {
                if (res.data.status == "success") {
                    setSettingData(res.data.sitesettings)
                    setImageUrl(res.data.settings_url)
                    setDefaultImage(res.data.default_image_baseurl)
                }
            }
            )

            apiServices.footersDataGetRequest().then(res => {
                if (res.data.status === "success") {
                    setFooter1(res.data.footerdata.footer_desc1)
                    setFooter2(res.data.footerdata.footer_desc2)
                    setFooter3(res.data.footerdata.footer_desc3)
                    setFooter4(res.data.footerdata.footer_desc4)

                }
            })
        }
        didMountRef.current = false;
    })

    const onbookingmodalclick = () => {
        setbookingmodal(true)
        
    }

    return (
        <footer>
            <div className="container">
                <div className="information">
                    <div className="row">

                        <div className="col-lg-12">
                            <div className="information__inner">
                                <ul className="list-unstyled information__list">
                                    <li>
                                        <div className="information__icon">
                                            <span className="icon-phone"></span>
                                        </div>
                                        <div className="information__content">
                                            <p className="information__sub-title">Call anytime</p>
                                            <h5 className="information__number">
                                                <a href={"tel:" + settingData.admin_support_mobile}>{settingData.admin_support_mobile}</a> ,
                                                <a href={"tel:" + settingData.admin_mobile}>{settingData.admin_mobile}</a>

                                            </h5>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="information__icon">
                                            <span className="icon-envelope"></span>
                                        </div>
                                        <div className="information__content">
                                            <p className="information__sub-title">Send email</p>
                                            <h5 className="information__number">
                                                <a href={"mailto:" + settingData.admin_email}>{settingData.admin_email}</a>
                                            </h5>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="information__icon">
                                            <span className="icon-location-arrow"></span>
                                        </div>
                                        <div className="information__content">
                                            <p className="information__sub-title">Visit office</p>
                                            <h5 className="information__number">
                                                <a href="https://goo.gl/maps/UwC4QzKbT7Y4c6wz7" target="new">{settingData.address}</a>
                                            </h5>
                                        </div>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-3">
                        <div className="footer-logo-section">
                            <a href="/"><img src="/assets/img/logo.png" className="footer-logo mb-4" /></a>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: footer1 }}>

                        </div>
                        {/* <p>Khao, Piyo, Nacho! Chokha Punjab invites you to connect with the vibrant culture, lively ambience and swadisht food of Punjab in Jaipur.</p> */}
                    </div>
                    <div className="col-lg-2 col-4 wow fadeInRight" data-wow-delay="0.4s" >
                        <div dangerouslySetInnerHTML={{ __html: footer2 }}>
                        </div>
                        <div className="footer-list">
                            <ul>
                                <li onClick={() => { onbookingmodalclick() }}>Bulk Booking</li>
                            </ul>
                        </div>

                    </div>
                    <div className="col-lg-2 col-4 wow fadeInRight" data-wow-delay="0.4s" dangerouslySetInnerHTML={{ __html: footer3 }}>

                    </div>
                    <div className="col-lg-2 col-4 wow fadeInRight" data-wow-delay="0.4s" >
                        <div className="mb-3" dangerouslySetInnerHTML={{ __html: footer4 }}>

                        </div>
                        <h5 className="footer-title">Follow Us on</h5>

                        <div className="sociallist">
                            <ul>
                                {settingData.facebook_url != null ?
                                    <li>

                                        <a href={settingData.facebook_url} target="new">
                                            <i className="icon-facebook-f"></i></a>
                                    </li> : ""}
                                {settingData.youtube_url != null ?
                                    <li>
                                        <a href={settingData.youtube_url} target="new">
                                            <i className="icon-youtube"></i></a>
                                    </li> : ""}
                                {settingData.instagram_url != null ?
                                    <li>
                                        <a href={settingData.instagram_url} target="new"><i className="icon-instagram"></i></a>
                                    </li> : ""}
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 wow fadeInRight mb-30" data-wow-delay="0.4s">

                        <h5 className="footer-title mb-5">Opening Hours</h5>

                        <p className="mb-0">We are available 7 days a week to assist you in any way!</p>

                        <ul className="work-hour-list">
                            <li><span className="day"><span>Monday to Friday</span> </span><span className="timing">5:30PM - 10:30PM</span></li>
                            <li><span className="day"><span>Saturday</span> </span><span className="timing">5:30PM - 10:30PM</span></li>
                            <li><span className="day"><span>Sunday</span> </span> <span className="timing">5:30PM - 10:30PM</span></li>
                        </ul>



                    </div>
                </div>
                {bookingmodal ? <CorporateBookingModal bookingmodal={bookingmodal} setbookingmodal={setbookingmodal} /> : null}
            </div>
        </footer>
    )
}
export default Footer