import React from 'react';
import { useParams } from "react-router-dom";
import { ApiService } from "../../Components/Services/apiservices";
import Header from "../../Components/Header";
let apiServices = new ApiService();
const TransactionFailed = () => {
  const { id } = useParams();
  const { status } = useParams();
  function getStatusText(statustxt) {
    switch (statustxt) {
      case '0':
        return `Transaction Pending`;
      case '2':
        return `Transaction Failed`;
      case '3':
        return `Transaction Cancelled`;
      case '4':
        return `Transaction Refund`;
      case '5':
        return `Transaction Expired`;
      case '6':
        return `Something Went Wrong`;

      default:
        return "Something Went Wrong";
    }
  }



  return (
    <>
      <Header />
      <section className="section-padding mt-4">
        <div className="step-first container text-center mt-80">
          <div className="section-title">
            {status == "4" ? <img src="/assets/img/refund.png" style={{ width: '100px' }} className="mb-20" /> : <img src="/assets/img/transactionfailed.png" style={{ width: '100px' }} className="mb-20" />}

            <p className='mt-3'>Your booking ID <strong>{id}</strong></p>
            <h2 className='mt-3'><strong>{getStatusText(status)}</strong></h2>
          </div>
          <div>

          </div>
        </div>
      </section>


    </>
  );
};

export default TransactionFailed;