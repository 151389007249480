import React, { useEffect, useState, useRef } from 'react';
import { useParams } from "react-router-dom";
import { ApiService } from "../../Components/Services/apiservices";
import Header from "../../Components/Header";



let apiServices = new ApiService();


const Thankyou = () => {
const { id } = useParams();
const didMountRef = useRef(true);
const [TransData, setTransData] = useState({});

    useEffect(() => {
        if (didMountRef.current) {
          
          const dataString = {
            trans_order_number: id,
          };

          apiServices.gettransactiondetails(dataString).then((res) => {
            if (res.data.status == "success") {
                console.log(res.data.data)
                setTransData(res.data.data);  
            }
          });
        }
        didMountRef.current = false;
      });

return(
    <>
          <Header />
         
          <section className="section-padding mt-4">
              <div className="step-first container text-center mt-80">
                <div className="section-title"> 
                    <img src="/assets/img/check-circle.gif" style={{width: '100px'}} className="mb-20"/>
                    <h2><strong>Thank You</strong></h2>
                    <h5>Your Enquiry Successfully Submitted</h5> 
                    {/* <p>Your booking ID <strong>#{orderId}</strong></p> */}
                    <p>Please check your email for ticket booking details.</p>
                  </div>
                  <div>
                  <table className="table mt-5">
    <thead>
      <tr>
      <th scope="col">Booking Id</th>
        <th scope="col">No. of Adults</th>
        <th scope="col">No. of Children</th>
        <th scope="col">Payment Mode</th>
        <th scope="col">Amount</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="row">{TransData.trans_order_number}</th>
        <td>{TransData.trans_no_of_adults}</td>
        <td>{TransData.trans_no_of_child}</td>
        <td>{TransData.trans_method}</td>
        <td>{TransData.trans_amt}</td>

      </tr>
    </tbody>
  </table>
  </div>  
              </div>
              </section>
              </>
    );
};

export default Thankyou;








