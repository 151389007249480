import React,{useEffect , useRef , useState} from "react"
import { useParams } from "react-router-dom";
import constant from '../../Components/Services/constant';
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import { ApiService } from "../../Components/Services/apiservices"
import {Helmet} from  "react-helmet"

let apiServices = new ApiService();

function Activities(){
   const [activitiesData , setActivitiesData] = useState([])
   const [baseUrl , setBaseUrl] = useState("")
   const [pageData, setPageData] = useState({});
  const [headerImageUrl, setHeaderImageUrl] = useState('');

    const didMountRef = useRef(true)

useEffect(() => {
    
 if (didMountRef.current) {
    apiServices.activitiesGetRequest().then(res=>{
        if(res.data.status == "success"){
            setActivitiesData(res.data.activitydata)
            setBaseUrl(res.data.activity_url)

        }
    })
    const dataString = {
      "page_url": "activities"
    };

    apiServices.pagePostRequest(dataString).then(res => {
      if (res.data.status === "success" && res.data.pageData != null) {
        setPageData(res.data.pageData);
        setHeaderImageUrl(res.data.page_header_url);
      }
    });

   
 }
  didMountRef.current = false;
    })
    return(
        <>
          <Helmet>
        <title>{pageData?.page_meta_title != null ? pageData.page_meta_title : "Chokha Punjab"}</title>
        <meta
          name="description"
          itemprop="description"
          content={pageData?.page_meta_desc != null ? pageData?.page_meta_desc : "Chokha Punjab"}
        />
        <meta name="keywords" content={pageData.page_meta_keyword} />
        <link rel="canonical" href={window.location.href} />
        <meta name="robots" content="index,follow" />
        <meta name="author" content="Chokha Punjab" />
        <meta property="og:title" content={pageData.page_meta_title} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Chokha Punjab" />
        <meta property="og:url" content={window.location.href} />
        {activitiesData[0]?.activity_image ? (
          <meta property="og:image" content={baseUrl + activitiesData[0]?.activity_image}  />
        ) : (
          <meta
            property="og:image"
            content={constant.FRONT_URL + '/assets/img/logo.png'}
          />
        )}
        {pageData?.page_meta_desc != null ? (
          <meta property="og:description" content={pageData?.page_meta_desc} />
        ) : (
          <meta property="og:description" content="Chokha Punjab" />
        )}

        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=5,user-scalable=1"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Chokha Punjab" />
        <meta name="twitter:title" content={pageData.page_meta_title} />
        {pageData?.page_meta_desc != null ? (
          <meta property="twitter:description" content={pageData?.page_meta_desc} />
        ) : (
          <meta property="twitter:description" content="Chokha Punjab" />
        )}
        {activitiesData[0]?.activity_image ? (
          <meta property="twitter:image" content={baseUrl + activitiesData[0]?.activity_image} />
        ) : (
          <meta
            property="twitter:image"
            content={constant.FRONT_URL + '/assets/img/logo.png'}
          />
        )}
        <meta name="twitter:url" content={window.location.href} />
      </Helmet>
        <Header/>
        <div className="subheader"> 
<div className="container">
<div className="row">
<div className="col-lg-12">
{pageData.page_header != null  ? <h1>{pageData.page_header}</h1>:""}

<nav aria-label="breadcrumb">
  <ol className="breadcrumb">
    <li className="breadcrumb-item"><a href="/">Home</a></li>
    <li className="breadcrumb-item active" aria-current="page">{pageData.page_header}</li>
  </ol>
</nav>

</div>
</div>
</div>
</div>

<section className="section-padding">
<div className="container">
<div className="row">
    {activitiesData.map((value,index)=>(
<div className="col-lg-4 wow fadeInUp" data-wow-delay="0.6s" key={index}>
<div className="activbox overlay-zoom">
<div className="inner">
<div className="thumbnail"><img alt="activity_image" src={value.activity_image !== null ? baseUrl + value.activity_image:"constant.DEFAULT_IMAGE"}/></div>

<div className="content">
<h5>{value.activity_name}</h5>

<div dangerouslySetInnerHTML={{__html : value.activity_desc}}></div>
</div>
</div>
</div>
</div>))}
</div>


</div>
</section>
        <Footer/>
        </>
    )
}
export default Activities