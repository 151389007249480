import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ApiService } from '../Services/apiservices';

let apiServices = new ApiService();

function Offers() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [Email , setEmail] = useState('')
const [successMessage, setSuccessmessage] = useState('')
const [sliderData, setSliderData] = useState('')
const [sliderImg, setSliderImg] = useState('')
const [errorMessage, setErrorMessage] = useState('')
const didMountRef = useRef(true);


useEffect(()=>{
  if(didMountRef.current){
    getSliderData()
  didMountRef.current = false;
  }
  })
useEffect(() => {
  
  const visitedBefore = localStorage.getItem('visitedNewsletter');
  if (!visitedBefore) {
    handleShow(); 
    localStorage.setItem('visitedNewsletter', 'true');
  }
}, []);

function isValidEmail(email) {
  return /\S+@\S+\.\S+/.test(email);
}

  const getSliderData = () =>{
    apiServices.sliderDataGetRequest().then(res=>{ 
      if(res.data.status == "success"){
        setSliderData(res.data.homeTopSliderData);
        setSliderImg(res.data.slider_img_path);
  }})
  }

const submitmessage = (e) => {
  e.preventDefault();
  if (!isValidEmail(Email)) {
    setErrorMessage('Please enter a valid email address.');
    return;
  }
 
        const dataString = {
            "newsletter_email": Email,
        }

    apiServices.newsletterPostRequest(dataString).then(res => { 
                if (res.data.status === "success") {
                    setSuccessmessage(res.data.message)
                    setEmail("")
                }
                else{
                    setErrorMessage(res.data.message)
                    setEmail("")
                    setTimeout(() => {
                      window.location.reload();
                    }, 2000);
                }

                setTimeout(() => {
                  window.location.reload();
                }, 2000);
            });
    }
  
  return (
    <>
 {sliderData ? (
<Modal show={show} onHide={handleClose} centered>
  {sliderData?.slider_image ? (
    <Modal.Body
      className='modal-body-with-background'
      style={{
        backgroundImage: `url(${sliderImg + sliderData.slider_image})`
      }}
    >
      <span>
        <button type="button" className="btn-close" onClick={handleClose}></button>
      </span>
      <div className="modal-content-news equote-content modal-set">
        {/* <p style={{color: "#000"}}><strong>SUBSCRIBE TO OUR NEWSLETTER</strong></p> */}
        <div className="row">
          {/* <div className="col-lg-12">
            {successMessage !== '' ? (
              <div className="alert alert-success" role="alert" style={{color: "green"}}>
                {successMessage}
              </div>
            ) : null}
            {errorMessage !== '' ? (
              <div className="alert alert-danger" role="alert" style={{color: "red"}}>
                {errorMessage}
              </div>
            ) : null}
            <div className="form-group mb-2 d-flex">
              <input
                type="text"
                name=""
                placeholder="Enter Your Mail Id"
                onChange={(e) => setEmail(e.target.value)}
                value={Email}
                style={{borderRadius:'5px'}}
              />
              <img src='/assets/img/next.png' onClick={submitmessage} className="next-img" />
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="mt-10 text-center">
              <a href="/book-tickets">
                <button type="button" className="modal-btn">Book Now</button>
                {/* top:0px
                font-size:16px */}
              </a>
            </div>
          </div>
        </div>
      </div>
    </Modal.Body>
  ) : null}
</Modal>
      ) : null}

    </>
  );
}

export default Offers;
