import React, { useEffect, useRef, useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
// import HomeGallery from "./homegallery";
import HomeBlogs from "./homeblogs";
import BookingModal from "../../Containers/BookingModal";
import Lightbox from "react-image-lightbox";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css";
import Modal from "react-bootstrap/Modal";
import { ApiService } from "../../Components/Services/apiservices";
import { Helmet } from "react-helmet";
import HomeTestimonials from "./hometestimonials";
import constant from "../../Components/Services/constant";
import HomeVideo from "./home_video";

let apiServices = new ApiService();
function Home() {
  const [pageData, setPageData] = useState({});
  const [headerImageUrl, setHeaderImageUrl] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [videoData, setVideoData] = useState([]);
  const [video, setVideo] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [imAGESARRAY, setIMAGESARRAY] = useState([]);

  const handleClose = () => setShow(false);
  const showmodal = () => {
    setShow(true);
    setOpen(true);
  };

  const handleClose1 = () => setShow1(false);

  const showmodal1 = (fsdds) => {
    setShow1(true);
    setVideo(fsdds);
  };

  const didMountRef = useRef(true);
  useEffect(() => {
    if (didMountRef.current) {
      const dataString = {
        page_url: "home",
      };

      apiServices.pagePostRequest(dataString).then((res) => {
        if (res.data.status == "success") {
          setPageData(res.data.pageData);
          setHeaderImageUrl(res.data.page_header_url);
        }
      });

      apiServices.videosDataGetRequest().then((res) => {
        if (res.data.status == "success") {
          setVideoData(res.data.videodata);
          setVideoUrl(res.data.video_url);
          setIMAGESARRAY(res.data.imagesarray);
        }
      });
    }
    didMountRef.current = false;
  }, []);
  return (
    <>
      <Helmet>
        <title>{pageData?.page_meta_title != null ? pageData.page_meta_title : "Chokha Punjab"}</title>
        <meta
          name="description"
          itemprop="description"
          content={pageData?.page_meta_desc != null ? pageData?.page_meta_desc : "Chokha Punjab"}
        />
        <meta name="keywords" content={pageData.page_meta_keyword} />
        <link rel="canonical" href={window.location.href} />
        <meta name="robots" content="index,follow" />
        <meta name="author" content="Chokha Punjab" />
        <meta property="og:title" content={pageData.page_meta_title} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Chokha Punjab" />
        <meta property="og:url" content={window.location.href} />
        {pageData?.page_header_image ? (
          <meta property="og:image" content={headerImageUrl + pageData.page_header_image} />
        ) : (
          <meta
            property="og:image"
            content={constant.FRONT_URL + '/assets/img/hero.png'}
          />
        )}
        {pageData?.page_meta_desc != null ? (
          <meta property="og:description" content={pageData?.page_meta_desc} />
        ) : (
          <meta property="og:description" content="Chokha Punjab" />
        )}

        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=5,user-scalable=1"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Chokha Punjab" />
        <meta name="twitter:title" content={pageData.page_meta_title} />
        {pageData?.page_meta_desc != null ? (
          <meta property="twitter:description" content={pageData?.page_meta_desc} />
        ) : (
          <meta property="twitter:description" content="Chokha Punjab" />
        )}
        {pageData?.page_header_image ? (
          <meta property="twitter:image" content={headerImageUrl + pageData.page_header_image} />
        ) : (
          <meta
            property="twitter:image"
            content={constant.FRONT_URL + '/assets/img/hero.png'}
          />
        )}
        <meta name="twitter:url" content={window.location.href} />
      </Helmet>

      <Header />
      <section className="hero-section d-flex align-items-center">
        <div className="container">
          <div className="hero-content row align-items-center">
            <div className="col-lg-6">
              <div className="hero-content-inner">
                <div
                  className="hero-sub-title mb-20 wow fadeInUp"
                  data-wow-delay="0.4s"
                >
                  Khao, Piyo, Nacho!
                </div>
                <h2
                  className="hero-title mb-50 wow fadeInUp"
                  data-wow-delay="0.6s"
                  style={{ fontSize: "64px" }}
                >
                  Swagat Hai Chokha Punjab Mein
                </h2>
                <h6>Jaha hai Khana aur Hansi Ka Vaada!</h6>
                <br />
                <h6>- A Unit of Chokhi Dhani Group</h6>
                <p
                  className="wow fadeInUp"
                  data-wow-delay="0.7s"
                  style={{ fontSize: "16px" }}
                >
                  From the land of Punjab to the Pink City, we bring you the
                  magic of a unique, colourful and extravagant Punjabi Pind.
                  Relish desi Swaad, dance to the beats of Bhangra, and blend in
                  the roots of Punjab, all at one destination.
                </p>
                <div
                  className="hero-button-group mt-50 d-flex align-items-center wow fadeInUp"
                  data-wow-delay="0.8s"
                >
                  <BookingModal />
                  <a
                    href="#"
                    className="video-button"
                    onClick={() => showmodal()}
                  >
                    <div className="dtr-video-popup dtr-video-button">
                      <span className="dtr-video-button-wrap-inner"></span>{" "}
                      <span className="dtr-border-animation dtr-border-1"></span>{" "}
                      <span className="dtr-border-animation dtr-border-2"></span>
                    </div>
                    <div className="dtr-video-button-text ml-15">Watch Now</div>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 wow fadeInRight mt-sm-50 text-center"
              data-wow-delay="0.4s"
            >
              <img
                // src="/assets/img/final.gif"
                // style={{ height: "600px" }}
                src="/assets/img/hero.png"
                className="img-fluid img-animation"
              />
            </div>
          </div>
        </div>
      </section>

<HomeVideo/>
      <section className="section-padding">
        <div className="container">
          <div
            className="section-title text-center mb-40 wow fadeInUp"
            data-wow-delay="0.4s"
          >
            <h2>Itthe Sab LIT Hai!</h2>
          </div>
          <div className="row">
            <div className="col-lg-3 col-6 wow fadeInUp" data-wow-delay="0.4s">
              <div className="sicon-box text-center">
                <div className="sicon-box-icon">
                  <img src="assets/img/01.png" />
                </div>
                <div className="sicon-box-content">
                  <h5>Mauja Hi Mauja </h5>
                  <p style={{ lineHeight: 1.3 }}>
                    Discover the sights and sounds of Punjab.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-6 wow fadeInUp" data-wow-delay="0.6s">
              <div className="sicon-box text-center">
                <div className="sicon-box-icon">
                  <img src="assets/img/02.png" />
                </div>
                <div className="sicon-box-content">
                  <h5>Swaad Ka Mela</h5>
                  <p style={{ lineHeight: 1.3 }}>
                    Serving the best of traditional Punjabi cuisine.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-6 wow fadeInUp" data-wow-delay="0.8s">
              <div className="sicon-box text-center">
                <div className="sicon-box-icon">
                  <img src="assets/img/03.png" />
                </div>
                <div className="sicon-box-content">
                  <h5>Khatron Ke Khiladi</h5>
                  <p style={{ lineHeight: 1.3 }}>
                    Explore Arcade Games, Rides and more.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-6 wow fadeInUp" data-wow-delay="0.9s">
              <div className="sicon-box text-center">
                <div className="sicon-box-icon">
                  <img src="assets/img/04.png" />
                </div>
                <div className="sicon-box-content">
                  <h5>Punjabi Pind Vibes</h5>
                  <p style={{ lineHeight: 1.3 }}>
                    Feel alive in the colourful Pind Di Vibes.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <HomeGallery /> */}

      <section className="section-padding">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="section-title mb-40">
                <h2 className="wow fadeInUp" data-wow-delay="0.4s">
                  Balle Balle Bites, Chokha Punjab Nights!
                </h2>
                <p className="wow fadeInUp" data-wow-delay="0.6s">
                  Dhaba style food, bhangra beats, village architecture and rich
                  heritage, experience the Pind life at Chokha Punjab, Jaipur.
                </p>
              </div>
              <div className="lig-box wow fadeInLeft" data-wow-delay="0.4s">
                <div className="lig-box-icon bg-yellow">
                  <img src="assets/img/icon01.png" />
                </div>
                <div className="lig-box-content">
                  <h5 className="mb-0">Pind Da Swad</h5>
                  <p className="mb-0 tx-14 line24">
                    From buttery naans straight out of the tandoor to sumptuous
                    paneer, at Chokha Punjab you’ll find all the classic Punjabi
                    signature dishes that will make you want to lick your
                    fingers.
                  </p>
                </div>
              </div>
              <div className="lig-box wow fadeInLeft" data-wow-delay="0.6s">
                <div className="lig-box-icon bg-orange">
                  <img src="assets/img/icon03.png" />
                </div>
                <div className="lig-box-content">
                  <h5 className="mb-0">Pind Di Masti</h5>
                  <p className="mb-0 tx-14 line24">
                    Your Punjabi adventure begins here! Enjoy Punjabi Dhol,
                    Arcade Games, Bullock Cart, Horse Riding, Puppet Show, Toy
                    Train and more.{" "}
                  </p>
                </div>
              </div>
              <div className="lig-box wow fadeInLeft" data-wow-delay="0.8s">
                <div className="lig-box-icon bg-darkblue">
                  <img src="assets/img/icon02.png" />
                </div>
                <div className="lig-box-content">
                  <h5 className="mb-0">Pindi Di Vibes</h5>
                  <p className="mb-0 tx-14 line24">
                    Feel the electrifying Pind Di vibes in the colourful
                    traditions, lively music, and authentic food that will
                    transport you to the heart of Punjab.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 wow fadeInRight mt-sm-50"
              data-wow-delay="0.4s"
            >
              <img
                src="assets/img/feature.png"
                className="img-fluid img-animation"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="section-padding menu-section">
        <div className="container">
          <div
            className="section-title text-center mb-40 wow fadeInUp"
            data-wow-delay="0.4s"
          >
            <h3 className="hero-sub-title-sec">Sarson da Saag te Makki di Roti</h3>
            <h2>Yahan khana hai 'Dil se Desi' aur swaad hai 'Mast Punjabi'!</h2>
          </div>
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-10">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.4s">
                  <div className="sicon-box menu-box text-center">
                    <div className="sicon-box-icon">
                      <img src="assets/img/icon/curry.png" className="mb-3" />
                    </div>
                    <h2 className="style-font">
                      <b>Curry</b>-on ka Nach!
                    </h2>
                    <p>
                      An indulgent selection of flavoursome curries, seasoned
                      with authentic spices.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.4s">
                  <div className="sicon-box menu-box text-center">
                    <div className="sicon-box-icon">
                      <img src="assets/img/icon/rice.png" className="mb-3" />
                    </div>
                    <h2 className="style-font">
                      Proper Patola <b>Pulao</b>
                    </h2>
                    <p>
                      A fragrant and flavorful rice dish cooked with a variety
                      of spices and vegetables.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.4s">
                  <div className="sicon-box menu-box text-center">
                    <div className="sicon-box-icon">
                      <img src="assets/img/icon/salad.png" className="mb-3" />
                    </div>
                    <h2 className="style-font">
                      Soniyon Jaisa <b>Salad</b>
                    </h2>
                    <p>
                      A refreshing and vibrant mix of crispy vegetables, often
                      accompanied by greens.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.4s">
                  <div className="sicon-box menu-box text-center">
                    <div className="sicon-box-icon">
                      <img src="assets/img/icon/thali.png" className="mb-3" />
                    </div>
                    <h2 className="style-font">
                      Tadka Marke De <b>Thali</b>
                    </h2>
                    <p>
                      A hearty meal consisting of a wide array of dishes,
                      representing the flavours of Punjab.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.4s">
                  <div className="sicon-box menu-box text-center">
                    <div className="sicon-box-icon">
                      <img
                        src="assets/img/icon/jaljeera.png"
                        className="mb-3"
                      />
                    </div>
                    <h2 className="style-font">Jaljeera</h2>
                    <p>
                      A zesty and tangy drink made with a blend of aromatic
                      spices, herbs, and lemon.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-padding">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 text-center wow fadeInLeft mt-sm-50"
              data-wow-delay="0.4s"
            >
              <img
                src="assets/img/fet.png"
                className="img-fluid img-animation mb-20"
              />
            </div>
            <div className="col-lg-6">
              <div className="section-title mtext-center mb-40">
                <h2
                  className="wow fadeInUp"
                  data-wow-delay="0.4s"
                >
                  Mauj Punjab di ab Jaipur vich
                </h2>
                <p
                  className="wow fadeInUp"
                  data-wow-delay="0.6s"
                >
                  Experience all things fun revolving around the culture and
                  heritage of Punjab- Baisakhi, Lohri, Food and more!
                </p>
              </div>
              <Swiper
                spaceBetween={12}
                // loop={true}
                breakpoints={{
                  340: {
                    slidesPerView: 1.5,
                  },
                  640: {
                    slidesPerView: 2,
                  },
                  768: {
                    slidesPerView: 3,
                  },
                  1000: {
                    slidesPerView: 3,
                  },
                  1200: {
                    slidesPerView: 3,
                  },
                }}
              >
                {videoData.map((value, index) => (
                  <SwiperSlide key={index}>
                    <a href="#" onClick={(e) => showmodal1(value.video_file)}>
                      <video
                        src={
                          value.video_file != null
                            ? videoUrl + value.video_file
                            : ""
                        }
                        loop
                        muted
                        autoplay="autoplay"
                        navigation={true}
                        playsInline
                        style={{ width: "100%" }}
                      />
                    </a>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      <HomeTestimonials />
      <HomeBlogs />

      {/* <div className="my-5 text-center">
        <h4>Please Note: We are a PET-FRIENDLY restaurant and village-fair!</h4>
      </div> */}

      <Footer />
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="video-modal-popup"
      >
        <Modal.Body>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          ></button>

          <iframe width="100%"
            height="430" src="https://www.youtube.com/embed/m2odhCkQJ3k?si=cQggUYxf2fMZQZY0" 
          title="YouTube video player" frameborder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
          referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </Modal.Body>
      </Modal>

      <Modal
        show={show1}
        onHide={handleClose1}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="video-modal-popup"
      >
        <Modal.Body>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose1}
          ></button>
          <iframe
            width="100%"
            height="430"
            src={videoUrl + video}
            title="Chokha Punjab"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default Home;
