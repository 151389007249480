import React, { useEffect, useRef, useState } from "react";
import constant from "../../Components/Services/constant";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import BookingModal from "../../Containers/BookingModal";
import { ApiService } from "../../Components/Services/apiservices";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import moment from 'moment';

let apiServices = new ApiService();
function Contact() {
  const [settingData, setSettingData] = useState("");
  const [ticketsData, setTicketsData] = useState([]);
  const [ticketImageUrl, setTicketImageUrl] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [pageData, setPageData] = useState("");
  const [headerImageUrl, setHeaderImageUrl] = useState("");
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Subject, setSubject] = useState("");
  const [Message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessmessage] = useState("");
  const { slug } = useParams();
  const didMountRef = useRef(true);
  const current_Date=new Date()
  useEffect(() => {
    if (didMountRef.current) {
      const dataString = {
        page_url: "contact",
      };
      apiServices.pagePostRequest(dataString).then((res) => {
        if (res.data.status === "success") {
          setPageData(res.data.pageData);
          setHeaderImageUrl(res.data.page_header_url);
        }
      });
      apiServices.settingsdataGetrequest().then((res) => {
        if (res.data.status == "success") {
          setSettingData(res.data.sitesettings);
          setImageUrl(res.data.settings_url);
        }
      });
      const dataStrings={
        date:moment(current_Date.toLocaleDateString()).format(`YYYY-MM-DD`)
      }
      apiServices.ticketslistPostRequest(dataStrings).then((res) => {
        if (res.data.status == "success") {
          setTicketsData(res.data.ticketsdata);
          setTicketImageUrl(res.data.ticket_url);
        }
      });
    }
    didMountRef.current = false;
  });

  function isValidEmail(Email) {
    return /\S+@\S+\.\S+/.test(Email);
  }

  const submitmessage = (e) => {
    e.preventDefault();
    if (Name == "") {
      document.getElementById("contact-name").style.border = "1px solid red";
      return false;
    } else if (Email == "") {
      document.getElementById("contact-email").style.border = "1px solid red";
      return false;
    } else if (!isValidEmail(Email)) {
      document.getElementById("contact-email").style.border = "1px solid red";
      return false;
    } else if (Mobile == "") {
      document.getElementById("contact-phone").style.border = "1px solid red";
      return false;
    } else if (Message == "") {
      document.getElementById("contact-message").style.border = "1px solid red";
      return false;
    } else {
      setName("");
      setEmail("");
      setMobile("");
      setMessage("");
      document.getElementById("contact-name").style.border = "1px solid var()";
      document.getElementById("contact-email").style.border = "1px solid var()";
      document.getElementById("contact-phone").style.border = "1px solid var()";
      document.getElementById("contact-message").style.border =
        "1px solid var()";

      const dataString = {
        contact_event: pageData.page_id,

        contact_name: Name,
        contact_email: Email,
        contact_mobile: Mobile,
        contact_message: Message,
      };
      apiServices.contactusPostRequest(dataString).then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          setSuccessmessage(res.data.message);
        } else if (res.data.status === "error") {
          setErrorMessage(res.data.message);
        }
        setTimeout(() => {
          setSuccessmessage("");
        }, 3000);
      });
    }
  };

  return (
    <>
     <Helmet>
        <title>{pageData?.page_meta_title != null ? pageData.page_meta_title : "Chokha Punjab"}</title>
        <meta
          name="description"
          itemprop="description"
          content={pageData?.page_meta_desc != null ? pageData?.page_meta_desc :"Chokha Punjab"}
        />
        <meta name="keywords" content={pageData.page_meta_keyword} />
        <link rel="canonical" href={window.location.href} />
        <meta name="robots" content="index,follow" />
        <meta name="author" content="Chokha Punjab" />
        <meta property="og:title" content={pageData.page_meta_title} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Chokha Punjab" />
        <meta property="og:url" content={window.location.href} />
        {pageData?.page_header_image ? (
                  <meta property="og:image" content={headerImageUrl + pageData.page_header_image} />
                ) : (
                  <meta
                    property="og:image"
                    content={constant.FRONT_URL + '/assets/img/logo.png'}
                  />
                )}
        {pageData?.page_meta_desc != null ? (
            <meta property="og:description" content={pageData?.page_meta_desc } />
            ) : (
            <meta property="og:description" content="Chokha Punjab" />
            )}

        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=5,user-scalable=1"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Chokha Punjab" />
        <meta name="twitter:title" content={pageData.page_meta_title} />
        {pageData?.page_meta_desc != null ? (
                    <meta property="twitter:description" content={pageData?.page_meta_desc} />
                ) : (
                    <meta property="twitter:description" content="Chokha Punjab" />
                )}
        {pageData?.page_header_image ? (
                  <meta property="twitter:image" content={ headerImageUrl + pageData.page_header_image} />
                ) : (
                  <meta
                  property="twitter:image"
                    content={constant.FRONT_URL + '/assets/img/logo.png'}
                  />
                )}
        <meta name="twitter:url" content={window.location.href} />
      </Helmet>
    

      <Header />

      <div className="subheader">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {pageData.page_header != null ? (
                <h1>{pageData.page_header}</h1>
              ) : (
                ""
              )}
              {pageData.page_sub_header != null ? (
                <h5>{pageData.page_sub_header}</h5>
              ) : (
                ""
              )}

              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {pageData.page_header}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <section className="section-padding">
        <div className="container">
          <div className="row  justify-content-space between">
            <div className="col-lg-7">
              <div className="section-title mb-40">
                <h3 className="style-font wow fadeInUp" data-wow-delay="0.4s">
                  Contact Us
                </h3>
                <h2 className="wow fadeInUp" data-wow-delay="0.4s">
                  Humse Judne Ke Liye, Contact Karein!
                </h2>
                <p className="wow fadeInUp" data-wow-delay="0.4s">
                  If you have any inquiries regarding group bookings, corporate
                  events, birthday parties, or other requests, kindly complete
                  the form provided below.
                </p>
              </div>
              {successMessage != "" ? (
                <div className="alert alert-success" role="alert">
                  {successMessage}
                </div>
              ) : (
                ""
              )}
              {errorMessage != "" ? (
                <div className="alert alert-danger" role="alert">
                  {errorMessage}
                </div>
              ) : (
                ""
              )}
              <div className="row g-3">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Your Name</label>
                    <input
                      name="contact-name"
                      id="contact-name"
                      type="text"
                      placeholder="Your name"
                      onChange={(e) => {setName(e.target.value);document.getElementById("contact-name").style.border = "none"}}
                      value={Name}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Email Address</label>
                    <input
                      name="contact-email"
                      id="contact-email"
                      type="email"
                      placeholder="Your email"
                      onChange={(e) => {setEmail(e.target.value);document.getElementById("contact-email").style.border = "none"}}
                      value={Email}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Mobile Number</label>
                    <input
                      name="contact-phone"
                      id="contact-phone"
                      type="text"
                      placeholder="Mobile number"
                      onChange={(e) => {setMobile(e.target.value);document.getElementById("contact-phone").style.border = "none"}}
                      value={Mobile}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Message</label>
                    <textarea
                      name="contact-message"
                      id="contact-message"
                      placeholder="Write message"
                      onChange={(e) => {setMessage(e.target.value);document.getElementById("contact-message").style.border = "none"}}
                      value={Message}
                      className="form-control"
                    ></textarea>
                  </div>
                </div>
                <div className="col-lg-12">
                  <button
                    type="submit"
                    className="btn btn-warning btn01-medium"
                    onClick={submitmessage}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="contact-address-section">
                <div className="section-title mb-10">
                  <h4 className="wow fadeInUp" data-wow-delay="0.4s">
                    Contact Info
                  </h4>
                </div>
                <div className="address-contact">
                  <ul>
                    <li>
                      <a
                        href="https://goo.gl/maps/zHSWnWpzHLpyqUUw8"
                        target="new"
                      >
                        <i className="icon-location-arrow mr-5"></i>
                        <span>{settingData.address}</span>
                      </a>
                    </li>
                    <li>
                      <a href={"mailto:" + settingData.admin_support_email}>
                        <i className="icon-envelope mr-5"></i>
                        <span>{settingData.admin_support_email}</span>
                      </a>
                    </li>
                    <li>
                      <a href={"tel:" + settingData.admin_support_mobile}>
                        <i className="icon-mobile mr-5"></i>
                        <span>{settingData.admin_support_mobile}</span>
                      </a>
                    </li>
                    <li>
                      <a href={"tel:" + settingData.admin_mobile}>
                        <i className="icon-mobile mr-5"></i>
                        <span>{settingData.admin_mobile}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="contact-address-section">
                <div className="section-title mb-40">
                  <h4 className="wow fadeInUp" data-wow-delay="0.4s">
                    Opening Hours
                  </h4>
                  <p className="mb-0">
                    Our support available to help you 24 hours a day, seven days
                    a week.
                  </p>

                  <ul className="work-hour-list">
                    <li>
                      <span className="day">
                        <span>Monday to Friday</span>
                      </span>
                      <span className="timing">5PM - 10:30PM</span>
                    </li>
                    <li>
                      <span className="day">
                        <span>Saturday</span>
                      </span>
                      <span className="timing">5PM - 10:30PM</span>
                    </li>
                    <li>
                      <span className="day">
                        <span>Sunday</span>
                      </span>
                      <span className="timing">5PM - 10:30PM</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="section-padding">
      <div className="container">
          <div className="row">
          <h1 className="text-center" style={{ fontWeight: "bold" }}>
  On the 31st Dec. Ticket Price for Chokha Punjab will be for Adults <br/> Rs. 1199 and For Children Rs. 499 Including Tax.
</h1>
            </div>
            </div>
</section> */}
      <section className="process section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="section-title mb-20">
                <h3 className="style-font wow fadeInUp" data-wow-delay="0.4s">
                  Our packages
                </h3>
                <h2>Choose The Best Packages</h2>
                <h5>All Seven Days</h5>
                <h5>Timings 5:30 PM TO 10:30 PM</h5>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="row">
                {ticketsData.map((value, index) => (
                  <div className="col-lg-6">
                    <div className="packagebox mb-3">
                      <div className="packagebox-header">
                        <h2>
                          <strong>{value.ticket_title}</strong>
                        </h2>
                        <img
                          alt="kids_image"
                          src={
                            value.ticket_image != null
                              ? ticketImageUrl + value.ticket_image
                              : constant.DEFAULT_IMAGE
                          }
                        />
                      </div>
                      <div className="packagebox-price">
                        ₹{value.ticket_selling_price}
                        <span>/person</span>
                      </div>

                      <div
                        className="packagebox-dec"
                        dangerouslySetInnerHTML={{ __html: value.ticket_desc }}
                      ></div>

                      <BookingModal />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="col-lg-12" style={{ textAlign: "center" }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7118.719820000044!2d75.667745!3d26.860304!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396c4b007d5f0775%3A0xe0f8fd6c737cd076!2sChokha%20Punjab!5e0!3m2!1sen!2sin!4v1687764801002!5m2!1sen!2sin"
          width={"80%"}
          height={"450"}
          style={{ border: "0" }}
          allowFullScreen=""
          loading={"lazy"}
        ></iframe>
      </div>

      <Footer />
    </>
  );
}

export default Contact;
