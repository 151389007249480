import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { ApiService } from "../../Components/Services/apiservices";
import { validEmail, validNumber } from '../../Components/Elements/Regex';
import SpinnerLoader from "../../Components/Elements/spinnerLoader";
import Alert from 'react-bootstrap/Alert';
import moment from 'moment';
let apiServices = new ApiService();

const CorporateBookingModal = ({  bookingmodal, setbookingmodal }) => {

  const didMountRef = useRef(true)
  const [show, setShow] = useState(false);
  const [step, setStep] = useState(1);
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [bookingDetails, setBookingDetails] = useState({
    no_of_adults: 1,
    no_of_child: 0,
    booking_date: new Date().toISOString().split('T')[0],
    user_name: "",
    user_mobile: "",
    user_email: "",
    user_message: "",
    booking_type: 1,
    child_price: 0,
    adult_price: 0
  });
  useEffect(() => {

    if (didMountRef.current) {
      const datastring = {
        date: moment(new Date()).format(`YYYY-MM-DD`)
      };
      apiServices.ticketslistPostRequest(datastring).then((res) => {
        
        if (res.data.status == "success") {
          setBookingDetails((prevState) => {
            return {
              ...prevState,
              child_price: res.data.ticketsdata[0]?.ticket_selling_price,
              adult_price: res.data.ticketsdata[1]?.ticket_selling_price,
            };
          });
          
        }
  
      });
    }
    didMountRef.current = false;
  },[])

  const handleClose = () => {
    setloader(false)
    setbookingmodal(false);
    setStep(1);
    setBookingDetails((prevState) => ({
      ...prevState,
      ['no_of_adults']: 1,
      ['no_of_child']: 0,
      ['booking_date']: new Date().toISOString().split('T')[0],
      ['user_name']: '',
      ['user_mobile']: '',
      ['user_email']: '',
      ['user_message']: '',
      ['booking_type']: 1,
      ['child_price']: 0,
      ['adult_price']: 0
    }));
  }

  const getticketlistprice = (date) => {
    const datastring = {
      date: moment(date.toString()).format(`YYYY-MM-DD`)
    };
    apiServices.ticketslistPostRequest(datastring).then((res) => {
      
      if (res.data.status == "success") {
        setBookingDetails((prevState) => {
          return {
            ...prevState,
            child_price: res.data.ticketsdata[0]?.ticket_selling_price,
            adult_price: res.data.ticketsdata[1]?.ticket_selling_price,
          };
        });
        
      }

    });
  }


  const confirmBooking = () => {
    setErrorMessage("");
    if (bookingDetails.user_name === "") {
      setErrorMessage("Please enter Full Name");
      return false;
    }
    if (bookingDetails.user_email === '') {
      setErrorMessage("Please enter Email Id");
      return false;
    }
    if (!validEmail.test(bookingDetails.user_email)) {
      setErrorMessage("Please enter valid Email Id");
      return false;
    }
    if (bookingDetails.user_mobile === "") {
      setErrorMessage("Please enter Mobile Number");
      return false;
    }
    if (!validNumber.test(bookingDetails.user_mobile)) {
      setErrorMessage("Please enter valid Mobile Number");
      return false;
    }
    if (bookingDetails.no_of_adults === "") {
      setErrorMessage("Please enter No of Adult");
      return false;
    }
    if (bookingDetails.no_of_child === "") {
      setErrorMessage("Please enter No of Child");
      return false;
    }
    if (bookingDetails.no_of_adults < 0) {
      setErrorMessage("Please enter valid No of Adult");
      return false;
    }

    if (bookingDetails.no_of_child < 0) {
      setErrorMessage("Please enter valid No of Child");
      return false;
    }
    if (bookingDetails.no_of_child == 0 && bookingDetails.no_of_adults == 0) {
      setErrorMessage("Please enter No of Adult or No of Child");
      return false;
    }
    if (bookingDetails.user_message === "") {
      setErrorMessage("Please enter message");
      return false;
    }
    
   
    if (bookingDetails.adult_price !== '' && bookingDetails.child_price !== '') {
      setloader(true)
      const dataString = {
        bookingDetails,
      }
      apiServices.confirmbookingurlPostRequest(dataString).then((res) => {
        if (res.data.status == "success") {
          setloader(false)
          setStep(2)
        } else {
          setloader(false)
          setErrorMessage(res.data.message)
        }
      });
    }

  };
  const onTodoChange = (e) => {
    setErrorMessage('')
    const { name, value } = e.target;
    setBookingDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div>


      <Modal show={bookingmodal} onHide={handleClose} className="btmodal">
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>


        <div className="btmodal-content bookform">
          {step === 1 && (
            <div className="step-first">
              <div className="section-title">
                <span className="section-title__tagline">Make a booking</span>
                <h4 className="section-title__title-small">
                  Bulk Bookings at Chokha Punjab
                </h4>
              </div>
              {successMessage !== "" ? (
                <Alert variant="success">{successMessage}</Alert>
              ) : null}
              {errorMessage !== "" ? (
                <Alert variant="danger">{errorMessage}</Alert>
              ) : null}
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <label>Full Name</label>
                    <input
                      type="text"
                      placeholder="Full Name"
                      name="user_name"
                      value={bookingDetails.user_name}
                      onChange={(e) => onTodoChange(e)}
                    ></input>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label>Email Id</label>
                    <input
                      type="text"
                      placeholder="Email Id"
                      name="user_email"
                      value={bookingDetails.user_email}
                      onChange={(e) => onTodoChange(e)}
                    ></input>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label>Mobile Number</label>
                    <input
                      type="number"
                      placeholder="Mobile No"
                      name="user_mobile"
                      value={bookingDetails.user_mobile}
                      onChange={(e) => onTodoChange(e)}
                    ></input>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mb-3">
                    <label>No of Adult</label>
                    <input
                      type="number"
                      placeholder="No of Adult"
                      name="no_of_adults"
                      min="0"
                      value={bookingDetails.no_of_adults}
                      onChange={(e) => onTodoChange(e)}
                    ></input>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mb-3">
                    <label>No of Child</label>
                    <input
                      type="number"
                      placeholder="No of Child"
                      name="no_of_child"
                      min="0"
                      value={bookingDetails.no_of_child}
                      onChange={(e) => onTodoChange(e)}
                    ></input>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mb-3">
                    <label>Date</label>
                    <input
                      type="date"
                      placeholder="Date"
                      name="booking_date"
                      min={new Date().toISOString().split('T')[0]}
                      value={bookingDetails.booking_date}
                      onChange={(e) => { onTodoChange(e); getticketlistprice(e.target.value) }}
                    ></input>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <label>Messsage</label>
                    <textarea name="user_message" value={bookingDetails.user_message} onChange={(e) => onTodoChange(e)}></textarea>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">

                    <button
                      className="btn btn-warning btn01 btn01-medium"
                      onClick={confirmBooking}
                      disabled={loader}
                    >
                      {loader ? (
                        <img src="/assets/img/loder01.gif" width="60px" height="11px" />
                      ) : (
                        "Continue"
                      )}

                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="step-first text-center">
              <div className="section-title">
                <img src="/assets/img/check-circle.gif" style={{ width: '100px' }} className="mb-20" />
                <h2><strong>Thank You</strong></h2>
                <h5>Your Enquiry Successfully Submitted</h5>
                {/* <p>Your booking ID <strong>#{orderId}</strong></p> */}
                <p className="text-center">Please check your email for ticket booking details.</p>
              </div>
            </div>
          )}
        </div>
      </Modal>

    </div>
  );
};

export default CorporateBookingModal;
