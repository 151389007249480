import React, { useEffect, useRef, useState } from "react";
import moment from 'moment';
import { ApiService } from "../../Components/Services/apiservices";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import BookingModal from "../BookingModal";
import constant from "../../Components/Services/constant";
import {Helmet} from  "react-helmet"

let apiServices = new ApiService();

const current_Date=new Date()

function BookTickets() {

    const didMountRef = useRef(true)
    const [ticketsData, setTicketsData] = useState([]);
    const [ticketImageUrl, setTicketImageUrl] = useState("");
    const [pageData, setPageData] = useState("");
    const [headerImageUrl, setHeaderImageUrl] = useState("");
    useEffect(()=>{
        if(didMountRef.current){
            getPagesData()
            bookTicket()
        }
        didMountRef.current = false

    })

    const getPagesData = () =>{
        const dataString ={
            page_url: "book-tickets"
        }
        apiServices.pagePostRequest(dataString).then((res) => {
            if (res.data.status === "success") {
              setPageData(res.data.pageData);
              setHeaderImageUrl(res.data.page_header_url);
            }
          });

    }

    const bookTicket = () => {
        const dataString = {
            date:moment(current_Date.toString()).format(`YYYY-MM-DD`)
        }
        apiServices.ticketslistPostRequest(dataString).then((res) => {
            if (res.data.status == "success") {
              setTicketsData(res.data.ticketsdata);
              setTicketImageUrl(res.data.ticket_url);
            }
          })

    }




  return (

    <>
       <Helmet>
        <title>{pageData?.page_meta_title != null ? pageData.page_meta_title : "Chokha Punjab"}</title>
        <meta
          name="description"
          itemprop="description"
          content={pageData?.page_meta_desc != null ? pageData?.page_meta_desc : "Chokha Punjab"}
        />
        <meta name="keywords" content={pageData.page_meta_keyword} />
        <link rel="canonical" href={window.location.href} />
        <meta name="robots" content="index,follow" />
        <meta name="author" content="Chokha Punjab" />
        <meta property="og:title" content={pageData.page_meta_title} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Chokha Punjab" />
        <meta property="og:url" content={window.location.href} />
        {pageData?.page_header_image ? (
          <meta property="og:image" content={headerImageUrl + pageData.page_header_image} />
        ) : (
          <meta
            property="og:image"
            content={constant.FRONT_URL + '/assets/img/logo.png'}
          />
        )}
        {pageData?.page_meta_desc != null ? (
          <meta property="og:description" content={pageData?.page_meta_desc} />
        ) : (
          <meta property="og:description" content="Chokha Punjab" />
        )}

        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=5,user-scalable=1"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Chokha Punjab" />
        <meta name="twitter:title" content={pageData.page_meta_title} />
        {pageData?.page_meta_desc != null ? (
          <meta property="twitter:description" content={pageData?.page_meta_desc} />
        ) : (
          <meta property="twitter:description" content="Chokha Punjab" />
        )}
        {pageData?.page_header_image ? (
          <meta property="twitter:image" content={headerImageUrl + pageData.page_header_image} />
        ) : (
          <meta
            property="twitter:image"
            content={constant.FRONT_URL + '/assets/img/logo.png'}
          />
        )}
        <meta name="twitter:url" content={window.location.href} />
      </Helmet>
        <Header/>
        <div className="subheader">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {pageData.page_header != null ? (
                <h1>{pageData.page_header}</h1>
              ) : (
                ""
              )}
              {pageData.page_sub_header != null ? (
                <h5>{pageData.page_sub_header}</h5>
              ) : (
                ""
              )}

              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {pageData.page_header}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <section className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="section-title mb-20">
                <h3 className="style-font wow fadeInUp" data-wow-delay="0.4s">
                  Our packages
                </h3>
                <h2>Choose The Best Packages</h2>
                <h5>All Seven Days</h5>
                <h5>Timings 5:30 PM TO 10:30 PM</h5>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="row">
                {ticketsData.map((value, index) => (
                  <div className="col-lg-6">
                    <div className="packagebox mb-3">
                      <div className="packagebox-header">
                        <h2>
                          <strong>{value.ticket_title}</strong>
                        </h2>
                        <img
                          alt="kids_image"
                          src={
                            value.ticket_image != null
                              ? ticketImageUrl + value.ticket_image
                              : constant.DEFAULT_IMAGE
                          }
                        />
                      </div>
                      <div className="packagebox-price">
                        ₹{value.ticket_selling_price}
                        <span>/person</span>
                      </div>

                      <div
                        className="packagebox-dec"
                        dangerouslySetInnerHTML={{ __html: value.ticket_desc }}
                      ></div>

                      <BookingModal />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </>



  )
}

export default BookTickets