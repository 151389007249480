import axios from 'axios';
import constant from './constant';


const pageUrl = "page-data";
const settingsdataUrl = "setting-data";
const testimonialUrl = "testimonials";
const galleryUrl = "gallery";
const gallerycategoryUrl = "gallery-category";
const categorywisegallaryUrl = 'category-wise-gallery'
const bloglistUrl =  "blog-list";
const blogdetailsUrl = "blog-details" ;
const contactusUrl ="contact-us-process"
const getfeaturedblogUrl = "featured-blogs";  
const activitiesUrl = "activities";
const videosUrl = "videos";
const eventsUrl = "events"
const bookingprocessUrl = "confirm-booking-process"
const applycouponUrl = "apply-coupon-process"
const ticketslistUrl = "tickets-list";
const footersUrl = "footer"
const memesUrl = "memes"
const allgalleryUrl = "all-gallery"
const menuUrl = "menus"
const confirmbookingurl = 'confirm-booking-process'
const createdpaytmprocessUrl = "createdPaytmOrderId"
const gettransactiondetailsUrl = "gettransactiondetails";
const newsletterurl = "newsletter";
const sliderDataUrl = "slider-data";





const client = axios.create({
    baseURL: constant.API_URL,
  });
  export class ApiService {
    
  

      pagePostRequest(request) {
        return client.post(pageUrl, request)
      }

      confirmbookingurlPostRequest(request) {
        return client.post(confirmbookingurl, request)
      }
  
      settingsdataGetrequest(){
      return client.get(settingsdataUrl);
      }
  
      testimonialsGetRequest(){
      return client.get(testimonialUrl);
      }

     galleryGetRequest(){
      return client.get(galleryUrl);
     }

    gallerycategoryGetRequest() {
      return client.get(gallerycategoryUrl)
    }
    categorywisegallaryPostRequest(request) {
      return client.post(categorywisegallaryUrl, request)
    }
    bloglistGetRequest() {
      return client.get(bloglistUrl);
    }
    blogdetailsPostRequest(request) {
      return client.post(blogdetailsUrl, request)
    }
     contactusPostRequest(request){
     return client.post(contactusUrl,request)

     }
     getfeaturedblogs(request){
      return client.post(getfeaturedblogUrl,request);
    }
    gettransactiondetails(request) {
      return client.post(gettransactiondetailsUrl, request)
    }
    activitiesGetRequest() {
      return client.get(activitiesUrl);
    }
    videosDataGetRequest() {
      return client.get(videosUrl);
    }
    eventsDataGetRequest() {
      return client.get(eventsUrl);
    }
 
    bookingprocessPostRequest(request){
      return client.post(bookingprocessUrl,request)
    }
    createdPaytmOrderId(request){
      return client.post(createdpaytmprocessUrl,request)
    }
    applycouponPostRequest(request){
      return client.post(applycouponUrl,request)
    }
    ticketslistPostRequest(request){
      return client.post(ticketslistUrl,request)
    }
    // ticketslistGetRequest(request){
    //     return client.post(ticketslistUrl,request);
    //    }
       footersDataGetRequest() {
        return client.get(footersUrl);
      }
      memesDataGetRequest(){
        return client.get(memesUrl);
       }
       allGalleryDataGetRequest(){
        return client.get(allgalleryUrl);
       }
       menuDataGetRequest(){
        return client.get(menuUrl);
       }
       newsletterPostRequest(request){
        return client.post(newsletterurl,request)
      }
      sliderDataGetRequest() {
        return client.get(sliderDataUrl);
      }

    }