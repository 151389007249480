import React, { useEffect, useRef, useState } from "react"
import moment from 'moment';
import constant from '../../Components/Services/constant';
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { ApiService } from "../../Components/Services/apiservices";
import {Helmet} from  "react-helmet"

let apiServices = new ApiService();
function Blog(){
    const [blogData , setBlogData] = useState([])
    const [blogImageUrl , setBlogImageUrl] = useState("")
    const[pageData, setPageData] = useState("")
    const[headerImageUrl , setHeaderImageUrl] = useState("")
    
    const didMountRef = useRef(true)
useEffect(() => {
if(didMountRef.current){
    const dataString = {
        "page_url" : "blogs"  
         }
         apiServices.pagePostRequest(dataString).then(res => {
          if(res.data.status === "success"){
              if(res.data.pageData != null){
            setPageData(res.data.pageData)
            setHeaderImageUrl(res.data.page_header_url)
      }
   }})

apiServices.bloglistGetRequest().then(res => {
    if(res.data.status == "success"){
        setBlogData(res.data.blogsData)
        setBlogImageUrl(res.data.blog_url)
}

didMountRef.current = false;
}, []);
}})

return (
    <>
    <Helmet>
        <title>{pageData?.page_meta_title != null ? pageData.page_meta_title : "Chokha Punjab"}</title>
        <meta
          name="description"
          itemprop="description"
          content={pageData?.page_meta_desc != null ? pageData?.page_meta_desc :"Chokha Punjab"}
        />
        <meta name="keywords" content={pageData.page_meta_keyword} />
        <link rel="canonical" href={window.location.href} />
        <meta name="robots" content="index,follow" />
        <meta name="author" content="Chokha Punjab" />
        <meta property="og:title" content={pageData.page_meta_title} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Chokha Punjab" />
        <meta property="og:url" content={window.location.href} />
        {pageData?.page_header_image ? (
                  <meta property="og:image" content={headerImageUrl + pageData.page_header_image} />
                ) : (
                  <meta
                    property="og:image"
                    content={constant.FRONT_URL + '/assets/img/logo.png'}
                  />
                )}
        {pageData?.page_meta_desc != null ? (
            <meta property="og:description" content={pageData?.page_meta_desc } />
            ) : (
            <meta property="og:description" content="Chokha Punjab" />
            )}

        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=5,user-scalable=1"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Chokha Punjab" />
        <meta name="twitter:title" content={pageData.page_meta_title} />
        {pageData?.page_meta_desc != null ? (
                    <meta property="twitter:description" content={pageData?.page_meta_desc} />
                ) : (
                    <meta property="twitter:description" content="Chokha Punjab" />
                )}
        {pageData?.page_header_image ? (
                  <meta property="twitter:image" content={ headerImageUrl + pageData.page_header_image} />
                ) : (
                  <meta
                  property="twitter:image"
                    content={constant.FRONT_URL + '/assets/img/logo.png'}
                  />
                )}
        <meta name="twitter:url" content={window.location.href} />
      </Helmet>


<Header />

<div className="subheader">
<div className="container">
<div className="row">
<div className="col-lg-12">
{pageData.page_header != null  ? <h1>{pageData.page_header}</h1>:""}
{pageData.page_sub_header != null ?<h5>{pageData.page_sub_header}</h5>:""}
<nav aria-label="breadcrumb">
  <ol className="breadcrumb">
    <li className="breadcrumb-item"><a href="/">Home</a></li>
    <li className="breadcrumb-item active" aria-current="page">{pageData.page_header}</li>
  </ol>
</nav>
</div>
</div>
</div>
</div>
<section className="section-padding">
<div className="container">
<div className="hero-content row align-items-center">      
{ blogData && blogData.length > 0 ?
    blogData.map((value,index)=>( 
    <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.4s" key={index} style={{marginBottom: "35px"}}>
    <div className="edu-blog blog-style-1">
        <div className="inner">
            <div className="thumbnail">
            <a href={`/blogs/${value.blog_slug}`}><img src={value.blog_image != null ? blogImageUrl + value.blog_image : constant.DEFAULT_IMAGE} alt="blog_image"></img></a>
            </div>
            <div className="content">
                <h5><a href={`/blogs/${value.blog_slug}`}>{value.blog_name}</a></h5>
                <ul className="blog-meta"><li><i className="icon-calendar-alt"></i>{moment(value.created_at).format('MMM DD, YYYY')}</li></ul>
                <p>{value.blog_short_description != null ? value.blog_short_description : ""} </p>
                <div className="read-more-btn">
                  <a href={`/blogs/${value.blog_slug}`}>Read More</a>
                  </div>
            </div>
        </div>
    </div>
    </div>
         )):""}
     </div>
</div>

</section>

<Footer />
</>
)



}
export default Blog;