// import './App.css';
import {BrowserRouter as Router,Routes,Route,Navigate,} from 'react-router-dom';
import Home from './Containers/Home';
import Pages from './Containers/Pages';
import Contact from './Containers/Contact';
// import Gallery from './Containers/Gallery';
import GalleryDetailPage from './Containers/GalleryDetailPage';
import Blog from './Containers/Blog';
import BlogDetailPage from './Containers/BlogDetailPage';
import Activities from './Containers/Activities';
import Events from './Containers/Events';
import Memes from './Containers/Memes';
import UpdatedGallery from './Containers/UpdatedGallery';
import Menu from './Containers/Menu';
import NotFound from './Containers/NotFound';
import Thankyou from './Containers/Thankyou';
import TransactionFailed from "./Containers/Transactionfailed/index"
import BookTickets from './Containers/BookTickets';



function App() {
  return (
    <div className="App">
      
      <Router>
   <Routes>
  <Route exact path ="/thankyou/:id" activeClassName= "active" element={<Thankyou /> } />
  <Route exact path ="/transaction-failed/:id/:status" activeClassName= "active" element={<TransactionFailed /> } />
   <Route exact path='/' activeClassName="active" element={ <Home /> }/>
   <Route exact path='/book-tickets' activeClassName="active" element={ <BookTickets /> }/>
   <Route exact path='/contact' activeClassName="active" element={ <Contact /> }/>
   {/* <Route exact path='/gallery' activeClassName="active" element={ <Gallery /> }/> */}
   <Route exact path='/gallery/:slug' activeClassName="active" element={ <GalleryDetailPage /> }/>
   <Route exact path='/blogs' activeClassName="active" element={ <Blog /> }/>
   <Route exact path='/activities' activeClassName="active" element={ <Activities /> }/>
   <Route exact path='/blogs/:slug' activeClassName="active" element={ <BlogDetailPage /> }/>
   <Route exact path='/events' activeClassName="active" element={ <Events /> }/>
   <Route exact path='/memes' activeClassName="active" element={ <Memes /> }/>
   <Route exact path='/gallery' activeClassName="active" element={ <UpdatedGallery /> }/>
   <Route exact path='/menu' activeClassName="active" element={ <Menu /> }/>



   <Route exact path="/404" activeClassName="active" element={<NotFound />} />
   


   
   <Route exact path='/:slug' activeClassName="active" element={ <Pages /> }/>
</Routes>
</Router>


    </div>
  );
}

export default App;
